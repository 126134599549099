import { Subject } from 'rxjs'

export function MortalityAware(): ClassDecorator
{
  return (target: any) => {
    if (!target.prototype.willUnmount$) {
      const originalWillUnmount = typeof target.prototype.componentWillUnmount === 'function'
        ? target.prototype.componentWillUnmount.bind(target)
        : null

      target.prototype.willUnmount$ = new Subject<true>()

      target.prototype.componentWillUnmount = function(): void
      {
        if (originalWillUnmount) {
          originalWillUnmount()
        }
        target.prototype.willUnmount$.next(true)
      }
    }
    else {
      console.error(
        'Tried to initialize class member `willUnmount$`, which is reserved for use by ' +
        'the @MortalityAware decorator.',
        target
      )
    }
  }
}
