import courtyardMarriottBuenaVista from '../../../../assets/courtyard-marriott-buena-vista.jpg'
import gaylordPalms from '../../../../assets/gaylord-palms.jpg'
import hotelsAndEventsMap from '../../../../assets/hotels-and-events-map.png'
import { AppStateAwareRouteComponent } from '../../../AppModel'
import HOTELS_MAP_URL from '../EmbeddedMaps/HotelsMapUrl'
import { Hotel } from './Hotel'
import './Hotels.scss'

export class Hotels extends AppStateAwareRouteComponent
{
  public render()
  {
    return <div id='hotels-container'>
      <h2>Hotels</h2>
      <ul id='hotels-list' className='list-unstyled'>
        <li>
          <Hotel
            name='Gaylord Palms'
            featuredImageSrc={gaylordPalms}
            websiteUrl='https://www.marriott.com/en-us/hotels/mcogp-gaylord-palms-resort-and-convention-center/overview'
            dollarSigns={3}
            overview={
              'Located a few minutes\' drive from the wedding, Gaylord Palms is a sprawling resort with tons of indoor and outdoor amenities and activities.'
            }
            address='6000 W Osceola Pkwy, Kissimmee, FL 34746'
            roomBlockInstructions='Yes — call 407-586-0000 and ask for the Shanbhogue / Mayer room block'
          />
        </li>
        <li>
          <Hotel
            name='Courtyard Orlando in the Marriott Village'
            featuredImageSrc={courtyardMarriottBuenaVista}
            websiteUrl={'https://book.passkey.com/e/50206078'}
            dollarSigns={2}
            overview={
              'This Marriott is walking distance from the wedding and offers a free shuttle to Disney World.'
            }
            address='8623 Vineland Ave, Orlando, FL 32821'
            roomBlockInstructions='Yes — follow this link to reserve before October 4th'
          />
        </li>
        {/*<li>
          <Hotel
            name='Reunion Resort'
            featuredImageSrc={reunionResort}
            websiteUrl='https://www.reunionresort.com/?utm_source=GMB&utm_medium=Listing&utm_campaign=Resort-Listing'
            dollarSigns={4}
            overview={
              'Down the street from our welcome cocktails, Reunion Resort offers suites with ' +
              'views of their luxurious 2,200-acre property, and is just a 20-minute drive ' +
              'from the airport.'
            }
            address='7593 Gathering Drive, Reunion, FL 34747'
            roomBlockInstructions={true}
          />
        </li>*/}
      </ul>

      <div className='map'>
        <div className='card'>
          <a href={HOTELS_MAP_URL}
            target='_blank'
            rel='noopener noreferrer'>

            <div className='map--main'
              style={{
                backgroundImage: 'url(' + hotelsAndEventsMap + ')',
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                height: '350px',
              }}>
            </div>

            <div className='map--footer'>
              <span className='label'>
                Click to view a map of recommended hotels
                <span>&nbsp;&nbsp;↗</span>
              </span>
            </div>

          </a>
        </div>
      </div>
    </div>
  }
}
