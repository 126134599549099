import { escapeRegExp, values } from 'lodash'
import { KeyboardEvent } from 'react'
import { distinctUntilKeyChanged, map } from 'rxjs/operators'
import { OrlandoRsvpSelect } from '../../../AppActions'
import { AppStateAwareRouteComponent, AppStateAwareRouteComponentProps } from '../../../AppModel'
import { forLifeOf } from '../../../helpers/forLifeOf'
import { MortalityAware } from '../../../helpers/MortalityAware'
import { OrlandoRsvp } from '../../../rpc/RsvpModel'
import { RsvpSearchForm } from './RsvpSearchForm'
import { RsvpSearchResults } from './RsvpSearchResults'

class RsvpSearchState
{
  public nameSearch = ''
  public rsvpSearchResults: OrlandoRsvp[] = []
  public activeRsvp?: OrlandoRsvp
}

export interface RsvpSearchProps extends AppStateAwareRouteComponentProps
{
  rsvps?: OrlandoRsvp[]
}

@MortalityAware()
export default class RsvpSearch extends AppStateAwareRouteComponent<RsvpSearchState, RsvpSearchProps>
{
  public state = new RsvpSearchState()

  public async componentDidMount(): Promise<void>
  {
    this.props.store
      .pipe(
        forLifeOf(this),
        distinctUntilKeyChanged('orlandoRsvpNameSearchAutofill'),
        map(({ orlandoRsvpNameSearchAutofill }) => orlandoRsvpNameSearchAutofill),
      )
      .subscribe((nameSearch) => this._handleNameChange(nameSearch as string))

    this.props.store
      .pipe(forLifeOf(this))
      .subscribe(({
        activeOrlandoRsvp,
        activeOrlandoRsvpIsAnimating
      }) =>
      {
        this.setState({ activeRsvp: activeOrlandoRsvp })

        if (!activeOrlandoRsvpIsAnimating && !activeOrlandoRsvp)
        {
          const firstResultButton = document.querySelector('#rsvp-search--results li .button') as HTMLElement
          if (firstResultButton)
          {
            firstResultButton.focus()
          }
        }
      })
  }

  public render()
  {
    return (
      <div id='rsvp-search' className={
        !!this.state.activeRsvp ? 'rsvp-is-active' : ''
      }>
        {
          <RsvpSearchForm
            activeRsvp={this.state.activeRsvp}
            inputValue={this.state.nameSearch}
            handleLastNameChange={this._handleNameChange}
            handleKeyDown={this._handleKeyDown}
          />
        }
        {
          !!this.state.rsvpSearchResults.length &&
          <RsvpSearchResults
            activeRsvp={this.state.activeRsvp}
            rsvps={this.state.rsvpSearchResults}
            handleRsvpSelect={this._handleRsvpSelect}
          />
        }
      </div>
    )
  }

  private _handleRsvpSelect = (rsvp: OrlandoRsvp): void =>
  {
    this.props.dispatch(new OrlandoRsvpSelect(rsvp.id))
  }

  private _handleNameChange = (nameSearch: string): void =>
  {
    this.setState({
      nameSearch: nameSearch || '',
      rsvpSearchResults: nameSearch && nameSearch.length > 5 && nameSearch.match(/^(\w+)\s\w\w(.+)?/gi) && this.props.rsvps
        ? this.props.rsvps.filter(
            (rsvp) => !!values(rsvp.invitees)
              .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
              .join(' ')
              .match(new RegExp(escapeRegExp(nameSearch), 'gi'))
          )
        : []
    })
  }

  private _handleKeyDown = (event: KeyboardEvent): void =>
  {
    if (event.which === 13 &&
      !!this.state.rsvpSearchResults[0])
    {
      this._handleRsvpSelect(this.state.rsvpSearchResults[0])
    }
  }
}
