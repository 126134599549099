import { values } from 'lodash'
import { OrlandoRsvp } from '../rpc/RsvpModel'

export function areSomeInviteesAttending(rsvp: OrlandoRsvp): boolean
{
  return values(rsvp.invitees).some(
    ({ eventsAttending }) => eventsAttending && Object.keys(eventsAttending).some(
      (eventKey) => eventsAttending[eventKey as 'welcomeCocktails'|'ceremony'|'reception']
    )
  )
}
