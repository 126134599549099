import { ChangeEvent } from 'react'
import { ToastEnqueue } from '../../../AppActions'
import { AppStateAwareComponent, AppStateAwareProps, AppToastType } from '../../../AppModel'
import { emailAddressIsValid } from '../../../helpers/emailAddressIsValid'
import { getOrAssumePrimary } from '../../../helpers/getOrAssumePrimary'
import { isAttending } from '../../../helpers/isAttending'
import { phoneNumberIsValid } from '../../../helpers/phoneNumberIsValid'
import { vaccinationDateIsValid } from '../../../helpers/vaccinationDateIsValid'
import { OrlandoInvitee } from '../../../rpc/InviteeModel'
import { OrlandoRsvp } from '../../../rpc/RsvpModel'
import ActiveRsvpHotel from './ActiveRsvpHotel'

export interface ActiveRsvpInviteeCardState
{
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  dietaryRestrictions: string
  vaccinationDate: string
  emailIsFocused: boolean
  phoneIsFocused: boolean
}

export interface ActiveRsvpInviteeCardProps extends AppStateAwareProps
{
  rsvp: OrlandoRsvp
  invitee: OrlandoInvitee
  index: number
  handleIsAttendingChange: (inviteeId: string, isAttending: boolean) => any
  handleEventAttendingChange: (
    event: ChangeEvent<HTMLInputElement>,
    inviteeId: string,
    which: 'welcomeCocktails'|'ceremony'|'reception',
  ) => any
  handleHotelExpectedChange: (
    event: ChangeEvent<HTMLInputElement>,
    inviteeId: string,
    hotelName: string,
  ) => any
  handleInviteeChange: (inviteeId: string, update: Partial<OrlandoInvitee>) => any
  handleApplyToAll: (templateInvitee: OrlandoInvitee) => Promise<any>
}

export class ActiveRsvpInviteeCard
  extends AppStateAwareComponent<ActiveRsvpInviteeCardState, ActiveRsvpInviteeCardProps>
{

  constructor(props: ActiveRsvpInviteeCardProps)
  {
    super(props)
    this.state = {
      firstName: props.invitee.firstName,
      lastName: props.invitee.lastName,
      emailAddress: props.invitee.emailAddress || '',
      phoneNumber: props.invitee.phoneNumber || '',
      dietaryRestrictions: props.invitee.dietaryRestrictions,
      vaccinationDate: props.invitee.vaccinationDate || '',
      emailIsFocused: false,
      phoneIsFocused: false,
    }
  }

  public componentDidUpdate(previousProps: ActiveRsvpInviteeCardProps): void {
    if (previousProps.invitee.dietaryRestrictions !== this.props.invitee.dietaryRestrictions) {
      this.setState({ dietaryRestrictions: this.props.invitee.dietaryRestrictions })
    }
  }

  public render()
  {
    return <div className={
        [
          'invitee-card',
          this.props.invitee.isPrimary ? 'is-primary' : '',
          isAttending(this.props.invitee.eventsAttending) ? 'is-attending' : ''
        ].join(' ')
      }>
      <div className='invitee-card--header'>
        <div className='invitee-card--header--left'>
          <div className='invitee-card--name'>
            {
              !this.props.invitee.nameIsUnknown ?
              <h3>{
                (this.props.invitee.firstName ? this.props.invitee.firstName + ' ' : '') + this.props.invitee.lastName
              }</h3> :
              isAttending(this.props.invitee.eventsAttending) ?
              <div className='invitee-card--name-inputs'>
                <input className='invitee-card--first-name-input'
                  id={`invitee-card-${this.props.index}--first-name-input`}
                  placeholder='First Name'
                  type='text'
                  value={this.state.firstName}
                  onChange={({ target }) => this.setState({ firstName: target.value })}
                  onBlur={() => this.props.handleInviteeChange(this.props.invitee.id, {
                    firstName: this.state.firstName
                  })}
                />
                <input className='invitee-card--last-name-input'
                  id={`invitee-card-${this.props.index}--last-name-input`}
                  placeholder='Last Name'
                  type='text'
                  value={this.state.lastName}
                  onChange={({ target }) => this.setState({ lastName: target.value })}
                  onBlur={() => this.props.handleInviteeChange(this.props.invitee.id, {
                    lastName: this.state.lastName
                  })}
                />
              </div> :
              <div className='invitee-card--name-placeholders'>
                Guest {this.props.index + 1}
              </div>
            }
          </div>
        </div>
      
        <div className='invitee-card--header--right'>
          <div>
            <label id={`invitee-${this.props.index}-card--attending--yes-label`}
              className='radio-group'>
              <input type='radio'
                id={`invitee-${this.props.index}-card--attending--yes-input`}
                checked={isAttending(this.props.invitee.eventsAttending)}
                onChange={(event) => this.props.handleIsAttendingChange(
                  this.props.invitee.id,
                  event.target.checked
                )}
              />
              <span>Attending</span>
            </label>

            <label id={`invitee-${this.props.index}-card--attending--no-label`}
              className='radio-group'>
              <input type='radio'
                id={`invitee-${this.props.index}-card--attending--no-input`}
                checked={!isAttending(this.props.invitee.eventsAttending)}
                onChange={(event) => this.props.handleIsAttendingChange(
                  this.props.invitee.id,
                  !event.target.checked
                )}
              />
              <span>Not attending</span>
            </label>
          </div>

        </div>
      
      </div>

      {this.props.invitee.isPrimary &&
      <div className='invitee-card--body--before'>
        <section id={`invitee-${this.props.index}-card--body--contact`}
          className='invitee-card--body--contact'>

          <div className='input-group'>
            <label htmlFor={`invitee-${this.props.index}-card--body--email--input`}
              className='legend'>
              Email address {
                this.props.invitee.isPrimary ||
                !isAttending(getOrAssumePrimary(this.props.rsvp).eventsAttending)
                  ? '(required)' : '(optional)'
              }
            </label>
            <input id={`invitee-${this.props.index}-card--body--email--input`}
              className={(this._emailValidationShouldDisplay() ? 'invalid' : 'valid')}
              type='email'
              value={this.state.emailAddress}
              onFocus={() => this.setState({ emailIsFocused: true })}
              onBlur={() => this.setState({ emailIsFocused: false })}
              onChange={({ target }) => {
                this.setState({ emailAddress: target.value })
                setTimeout(() => {
                  this.props.handleInviteeChange(this.props.invitee.id, {
                    emailAddress: this.state.emailAddress
                  })
                })
              }}
            />
            {
              this._emailValidationShouldDisplay() &&
              <span className='label error-message'>
                Please provide a valid email
              </span>
            }
          </div>

          <div className='input-group'>
            <label htmlFor={`invitee-${this.props.index}-card--body--phone--input`}
              className='legend'>
              Mobile phone (optional)
            </label>
            <input id={`invitee-${this.props.index}-card--body--phone--input`}
              className={(this._phoneValidationShouldDisplay() ? 'invalid' : 'valid')}
              type='tel'
              value={this.state.phoneNumber}
              onFocus={() => this.setState({ phoneIsFocused: true })}
              onBlur={() => this.setState({ phoneIsFocused: false })}
              onChange={({ target }) => {
                this.setState({ phoneNumber: target.value })
                setTimeout(() => {
                  this.props.handleInviteeChange(this.props.invitee.id, {
                    phoneNumber: this.state.phoneNumber
                  })
                })
              }}
            />
            {
              this._phoneValidationShouldDisplay() &&
              <span className='label error-message'>
                Please provide a valid phone number
              </span>
            }
          </div>
        </section>
      </div>}

      {
        isAttending(this.props.invitee.eventsAttending) &&
        <div className={
          this.props.invitee.isPrimary
          ? 'invitee-card--body is-primary' : 'invitee-card--body'
        }>

          <p>We ask that all guests be fully vaccinated against COVID-19 by October 15.</p>

          <section id={`invitee-${this.props.index}-card--body--vaccinated`}
            className='invitee-card--body--vaccinated'>

            <div className='input-group'>
              <label htmlFor={`invitee-${this.props.index}-card--body--vaccination-date--input`}
                className='legend'>
                Date this guest was or is scheduled to be vaccinated:
              </label>
              <input id={`invitee-${this.props.index}-card--body--vaccination-date--input`}
                className={(this._vaccinationDateValidationShouldDisplay() ? 'invalid' : 'valid')}
                type='date'
                max='2021-10-15'
                value={this.state.vaccinationDate}
                onChange={({ target }) => {
                  this.setState({ vaccinationDate: target.value })
                  setTimeout(() => {
                    this.props.handleInviteeChange(this.props.invitee.id, {
                      vaccinationDate: this.state.vaccinationDate
                    })
                    this.props.handleIsAttendingChange(this.props.invitee.id, true)
                  })
                }}
              />
              {
                this._vaccinationDateValidationShouldDisplay() &&
                <span className='label error-message'>
                  Please enter a vaccination date for this guest.
                </span>
              }
            </div>

          </section>

          {
            !this.props.invitee.isPrimary &&
            !this.props.invitee.isKnownToBeAdult &&
            <section id={`invitee-${this.props.index}-card--body--vaccine-ineligible`}
              className='invitee-card--body--vaccine-ineligible'>
              <div className='input-group'>
                <label htmlFor={`invitee-${this.props.index}-card--body--vaccine-ineligible--input`}
                  className='radio-group'>

                  <input type='checkbox'
                    id={`invitee-${this.props.index}-card--body--vaccine-ineligible--input`}
                    checked={!!this.props.invitee.isIneligibleForVaccine}
                    value={!!this.props.invitee.isIneligibleForVaccine ? 'true' : 'false'}
                    onChange={(event) => this.props.handleInviteeChange(
                      this.props.invitee.id,
                      { isIneligibleForVaccine: event.target.checked }
                    )}
                  />

                  <span>This guest is a child and is ineligible for the COVID-19 vaccine.</span>

                </label>
              </div>
            </section>
          }

          {
            !this.props.invitee.isPrimary &&
            !this.props.invitee.isKnownToBeAdult &&
            <fieldset id={`invitee-${this.props.index}-card--body--age`}
              className='invitee-card--body--age extra-padding-y'>

              <legend>Is this guest 12 years of age or younger?</legend>

              <label id={`invitee-${this.props.index}-card--body--age--yes-label`}
                className='radio-group'>
                <input type='radio'
                  id={`invitee-${this.props.index}-card--body--age--yes-input`}
                  checked={this.props.invitee.isTwelveOrYounger}
                  onChange={(event) => this.props.handleInviteeChange(
                    this.props.invitee.id,
                    { isTwelveOrYounger: event.target.checked }
                  )}
                />
                <span>Yes</span>
              </label>

              <label id={`invitee-${this.props.index}-card--body--age--no-label`}
                className='radio-group'>
                <input type='radio'
                  id={`invitee-${this.props.index}-card--body--age--no-input`}
                  checked={!this.props.invitee.isTwelveOrYounger}
                  onChange={(event) => this.props.handleInviteeChange(
                    this.props.invitee.id,
                    { isTwelveOrYounger: !event.target.checked }
                  )}
                />
                <span>No</span>
              </label>

            </fieldset>
          }

          {
            this.props.invitee.isTwelveOrYounger &&
            <fieldset id={`invitee-${this.props.index}-card--body--age`}
              className='invitee-card--body--age extra-padding-y'>

              <legend>Is this guest 4 years of age or younger?</legend>

              <label id={`invitee-${this.props.index}-card--body--age--yes-label`}
                className='radio-group'>
                <input type='radio'
                  id={`invitee-${this.props.index}-card--body--age--yes-input`}
                  checked={this.props.invitee.isFourOrYounger}
                  onChange={(event) => this.props.handleInviteeChange(
                    this.props.invitee.id,
                    { isFourOrYounger: event.target.checked }
                  )}
                />
                <span>Yes</span>
              </label>

              <label id={`invitee-${this.props.index}-card--body--age--no-label`}
                className='radio-group'>
                <input type='radio'
                  id={`invitee-${this.props.index}-card--body--age--no-input`}
                  checked={!this.props.invitee.isFourOrYounger}
                  onChange={(event) => this.props.handleInviteeChange(
                    this.props.invitee.id,
                    { isFourOrYounger: !event.target.checked }
                  )}
                />
                <span>No</span>
              </label>

            </fieldset>
          }

          {/* <fieldset id={`invitee-${this.props.index}-card--body--which-events`}
            className='invitee-card--body--which-events'>

            <legend>Which events?</legend>

            <ActiveRsvpEventAttending
              eventKey='welcomeCocktails'
              location={events.welcomeCocktails.venueName}
              eventName={events.welcomeCocktails.name}
              date={events.welcomeCocktails.date}
              invitee={this.props.invitee}
              index={this.props.index}
              onChange={(event) => this.props.handleEventAttendingChange(
                event,
                this.props.invitee.id,
                'welcomeCocktails',
              )}
            />

            <ActiveRsvpEventAttending
              eventKey='ceremony'
              location={events.ceremony.venueName}
              eventName={events.ceremony.name}
              date={events.ceremony.date}
              invitee={this.props.invitee}
              index={this.props.index}
              onChange={(event) => this.props.handleEventAttendingChange(
                event,
                this.props.invitee.id,
                'ceremony',
              )}
            />

            <ActiveRsvpEventAttending
              eventKey='reception'
              location={events.reception.venueName}
              eventName={events.reception.name}
              date={events.reception.date}
              invitee={this.props.invitee}
              index={this.props.index}
              onChange={(event) => this.props.handleEventAttendingChange(
                event,
                this.props.invitee.id,
                'reception',
              )}
            />
          </fieldset> */}

          <section id={`invitee-${this.props.index}-card--body--dietary`}
            className='invitee-card--body--dietary'>
            <div className='input-group'>
              <label htmlFor={`invitee-${this.props.index}-card--body--dietary--input`}
                className='legend'>
                Dietary restrictions?
              </label>
              <input id={`invitee-${this.props.index}-card--body--dietary--input`}
                type='text'
                placeholder='e.g. vegan, soy allergic, GF'
                value={this.state.dietaryRestrictions}
                onChange={({ target }) => this.setState({ dietaryRestrictions: target.value })}
                onBlur={() => this.props.handleInviteeChange(this.props.invitee.id, {
                  dietaryRestrictions: this.state.dietaryRestrictions
                })}
              />
            </div>
          </section>

          {
            (
              this.props.invitee.isPrimary ||
              !isAttending(getOrAssumePrimary(this.props.rsvp).eventsAttending)
            ) &&
            <fieldset id={`invitee-${this.props.index}-card--body--parking`}
              className='invitee-card--body--parking extra-padding-y'>

              <legend>Will you be parking a car at the wedding?</legend>

              <label id={`invitee-${this.props.index}-card--body--parking--yes-label`}
                className='radio-group'>
                <input type='radio'
                  id={`invitee-${this.props.index}-card--body--parking--yes-input`}
                  checked={!!this.props.invitee.willRequireParking}
                  onChange={(event) => this.props.handleInviteeChange(
                    this.props.invitee.id,
                    { willRequireParking: event.target.checked }
                  )}
                />
                <span>Yes</span>
              </label>

              <label id={`invitee-${this.props.index}-card--body--parking--no-label`}
                className='radio-group'>
                <input type='radio'
                  id={`invitee-${this.props.index}-card--body--parking--no-input`}
                  checked={!this.props.invitee.willRequireParking}
                  onChange={(event) => this.props.handleInviteeChange(
                    this.props.invitee.id,
                    { willRequireParking: !event.target.checked }
                  )}
                />
                <span>No, we'll take cabs or shuttles</span>
              </label>

            </fieldset>
          }

          <fieldset id={`invitee-${this.props.index}-card--body--which-hotel`}
            className='invitee-card--body--which-hotel'>

            <legend>Where will you most likely stay?</legend>

            <ActiveRsvpHotel
              hotelName='Gaylord Palms'
              websiteUrl='https://www.marriott.com/en-us/hotels/mcogp-gaylord-palms-resort-and-convention-center/overview/'
              invitee={this.props.invitee}
              index={this.props.index}
              onChange={(event) => this.props.handleHotelExpectedChange(
                event,
                this.props.invitee.id,
                'Gaylord Palms',
              )}
            />

            <ActiveRsvpHotel
              hotelName='Courtyard Orlando in the Marriott Village'
              websiteUrl='https://book.passkey.com/e/50206078'
              invitee={this.props.invitee}
              index={this.props.index}
              onChange={(event) => this.props.handleHotelExpectedChange(
                event,
                this.props.invitee.id,
                'Courtyard Orlando in the Marriott Village',
              )}
            />

            <ActiveRsvpHotel
              hotelName=''
              websiteUrl=''
              invitee={this.props.invitee}
              index={this.props.index}
              onChange={(event) => this.props.handleHotelExpectedChange(
                event,
                this.props.invitee.id,
                '',
              )}
            />
          </fieldset>

          <section className='input-group'>
            <label htmlFor={`invitee-${this.props.index}-card--body--book-exchange--input`}
              className='radio-group'>

              <input type='checkbox'
                id={`invitee-${this.props.index}-card--body--book-exchange--input`}
                checked={!!this.props.invitee.bookExchange}
                value={!!this.props.invitee.bookExchange ? 'true' : 'false'}
                onChange={(event) => this.props.handleInviteeChange(
                  this.props.invitee.id,
                  { bookExchange: event.target.checked }
                )}
              />

              <span>
                I'd like to participate in the&nbsp;
                <a href='https://dannyandesha.com/orlando/book-exchange'
                  target='_blank'
                  rel='noopener noreferrer'>
                  book exchange</a>!
              </span>

            </label>
          </section>

          {
            // Object.keys(this.props.rsvp.invitees).length > 1 &&
            // <button type='button'
            //   className='pill'
            //   onClick={() => this._handleApplyToAllClick(this.props.invitee)}>
            //   <span className='label'
            //     style={{ textDecoration: 'underline' }}>
            //     Apply these answers to everyone
            //   </span>
            // </button>
          }
        </div>
      }
    </div>
  }

  private async _handleApplyToAllClick(templateInvitee: OrlandoInvitee): Promise<void>
  {
    await this.props.handleApplyToAll(templateInvitee)
    this.props.dispatch(new ToastEnqueue({
      type: AppToastType.SUCCESS,
      message: 'Applied these answers to all!',
    }))
  }

  private _emailValidationShouldDisplay(): boolean
  {
    return !emailAddressIsValid(this.state.emailAddress) &&
      !this.state.emailIsFocused
  }

  private _phoneValidationShouldDisplay(): boolean
  {
    return !!this.state.phoneNumber &&
      !phoneNumberIsValid(this.state.phoneNumber) &&
      !this.state.phoneIsFocused
  }

  private _vaccinationDateValidationShouldDisplay(): boolean
  {
    return !vaccinationDateIsValid(this.props.invitee)
  }
}
