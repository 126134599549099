import { BangaloreInvitee, OrlandoInvitee } from "./InviteeModel";

abstract class Rsvp {
  public dateCreated = new Date().toString();
}

export interface OrlandoRsvp extends Rsvp {
  id: string;
  partyName: string;
  dateSubmitted: string;
  dateUpdated: string;
  partyCount: number;
  guestsOf: string;
  invitees: { [key: string]: OrlandoInvitee };
  message: string;
  addressCity: string;
  addressStateOrCountry: string;
}

export function getOrlandoInviteesLength(rsvp: OrlandoRsvp): number {
  return Object.keys(rsvp.invitees).length;
}

export class BangaloreRsvp extends Rsvp {
  constructor(init?: Partial<BangaloreRsvp>) {
    super();
    if (init) {
      this.invitees = init.invitees as BangaloreInvitee[];
      this.needsHotelHelp = init.needsHotelHelp as boolean;
      this.needsAirportPickup = init.needsAirportPickup as boolean;
    }
  }
  public invitees: BangaloreInvitee[] = [new BangaloreInvitee()];
  public needsHotelHelp = false;
  public needsAirportPickup = false;
}
