import { OperatorFunction } from 'rxjs'
import { filter } from 'rxjs/operators'

export interface Type<T> extends Function
{
  new (...args: any[]): T
}

export function instanceOf<ValueType>(ctor: Type<ValueType>): OperatorFunction<any, ValueType>
{
  return filter<ValueType>((update) => update instanceof ctor)
}
