import axios from "axios";
import type firebase from "firebase/app";
import { ReplaySubject } from "rxjs";
import { filter, shareReplay } from "rxjs/operators";
import { orlandoRsvpsRef } from "../../data-access";
import { OrlandoRsvp } from "./RsvpModel";

class OrlandoCache {
  // public hasFetchedOnce = false
  public rsvps?: { [key: string]: OrlandoRsvp };
  public lastActiveRsvpId?: string | null;
}
const orlandoCache = new OrlandoCache();

// Orlando.
const SEND_ORLANDO_RSVP_EMAIL_URL =
  "https://us-central1-danny-and-esha.cloudfunctions.net/sendRsvpEmailForOrlando";
export async function sendOrlandoRsvpEmail(rsvp: OrlandoRsvp) {
  return axios.post(SEND_ORLANDO_RSVP_EMAIL_URL, rsvp);
}

export const streamOrlandoRsvps = () => {
  const subject = new ReplaySubject<{ [key: string]: OrlandoRsvp }>(1);
  const callback = (snapshot: firebase.database.DataSnapshot | null) => {
    if (snapshot && snapshot.val()) {
      subject.next(snapshot.val());
    }
  };

  orlandoRsvpsRef.on("value", callback);

  return {
    rsvps$: subject.pipe(
      filter((rsvps) => !!rsvps),
      shareReplay(1)
    ),
    tearDown: () => {
      subject.complete();
      orlandoRsvpsRef.off("value", callback);
    },
  };
};

export async function updateOrlandoRsvp(
  rsvpId: string,
  rsvpUpdate: Partial<OrlandoRsvp>
): Promise<void> {
  function removeUndefinedValues(
    partialRsvp: Partial<OrlandoRsvp>
  ): Partial<OrlandoRsvp> {
    return Object.keys(partialRsvp).reduce(
      (update, key) => {
        const value = update[key as keyof Partial<OrlandoRsvp>];
        if (typeof value === "object") {
          update[key as keyof Partial<OrlandoRsvp>] = removeUndefinedValues(
            value
          ) as any;
        }
        if (value === undefined) {
          delete update[key as keyof Partial<OrlandoRsvp>];
        }
        return update;
      },
      { ...partialRsvp }
    );
  }
  const updateWithoutNulls = removeUndefinedValues(rsvpUpdate);
  await orlandoRsvpsRef.child(rsvpId).update(updateWithoutNulls);
}

export async function getOrlandoRsvp(
  rsvpId: string
): Promise<OrlandoRsvp | undefined> {
  return orlandoRsvpsRef
    .child(rsvpId)
    .once("value")
    .then((snapshot) => snapshot.val());
}

export function getRsvpIdForCurrentUser(): string | null {
  if (!orlandoCache.lastActiveRsvpId) {
    orlandoCache.lastActiveRsvpId = window.localStorage.getItem(
      "orlando.lastActiveRsvpId"
    );
  }
  return orlandoCache.lastActiveRsvpId;
}

export async function updateRsvpForCurrentUser(
  rsvp: OrlandoRsvp
): Promise<void> {
  orlandoCache.lastActiveRsvpId = rsvp.id;
  window.localStorage.setItem("orlando.lastActiveRsvpId", rsvp.id);
}

// export async function readOrlandoRsvpsOnce(): Promise<{ [key: string]: OrlandoRsvp } | undefined> {
//   if (orlandoCache.hasFetchedOnce) {
//     return orlandoCache.rsvps
//   }
//   const snapshot = await orlandoRsvpsRef.once('value')
//   const rsvps = snapshot.val() as { [key: string]: OrlandoRsvp }

//   // Filter out priority groups below B-.
//   const filteredRsvps = Object.keys(rsvps).reduce<{ [key: string]: OrlandoRsvp }>((acc, rsvpId) => {
//     const rsvp = rsvps[rsvpId]
//     if (rsvp.priorityGroup.match(/^[AB]$/)) {
//       acc[rsvpId] = rsvp
//     }
//     return acc
//   }, {})
//   orlandoCache.hasFetchedOnce = true
//   orlandoCache.rsvps = filteredRsvps
//   return filteredRsvps
// }
