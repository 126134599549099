import { RouteComponentProps } from 'react-router-dom'
import disneySprings from '../../../../assets/disney-springs.jpg'
import disneyWorld from '../../../../assets/disney-world.jpg'
import escapeGame from '../../../../assets/escape-game.jpg'
import lakeEolaPark from '../../../../assets/lake-eola-park.jpg'
import outletMall from '../../../../assets/outlet-mall.jpg'
import universalResort from '../../../../assets/universal-resort.jpg'
import './ThingsToDo.scss'
import { ThingToDo } from './ThingToDo'

export default function(_props: RouteComponentProps)
{
  return (
    <div id='things-to-do-container'>
      <h2>Things To Do</h2>
      <ul id='things-to-do-list' className='list-unstyled'>
        <li>
          <ThingToDo
            name='Player 1 Video Game Bar'
            description='Drinks and arcade games — need we say more?'
            websiteUrl='https://player1orlando.com'
            featuredImageSrc='https://player1orlando.com/i/hero-homepage.jpg'
            distanceFromVenue='2 miles'
          />
        </li>

        <li>
          <ThingToDo
            name='Orlando Vineland Premium Outlets'
            description='A plaza with discount designer storefronts.'
            websiteUrl='https://www.premiumoutlets.com/outlet/orlando-vineland'
            featuredImageSrc={outletMall}
            distanceFromVenue='2 miles'
          />
        </li>

        <li>
          <ThingToDo
            name='Disney Springs'
            description='Outdoor plaza with shopping, restaurants, and Disney decor. We recommend Blaze Pizza here!'
            websiteUrl='https://www.disneysprings.com'
            featuredImageSrc={disneySprings}
            distanceFromVenue='2 miles'
          />
        </li>

        <li>
          <ThingToDo
            name='The Escape Game Orlando'
            description='Our favorite themed escape rooms! Very immersive and a fun way to spend an hour.'
            websiteUrl='https://theescapegame.com/orlando'
            featuredImageSrc={escapeGame}
            distanceFromVenue='7 miles'
          />
        </li>

        <li>
          <ThingToDo
            name='Walt Disney World'
            description='Where dreams come true...'
            websiteUrl='https://disneyworld.disney.go.com/admission/tickets'
            featuredImageSrc={disneyWorld}
            distanceFromVenue='8 miles'
          />
        </li>

        <li>
          <ThingToDo
            name='Universal Orlando Resort'
            description='Theme park with immersive rides. Also, Harry Potter World!'
            websiteUrl='https://www.universalorlando.com/web/en/us'
            featuredImageSrc={universalResort}
            distanceFromVenue='10 miles'
          />
        </li>

        <li>
          <ThingToDo
            name='Lake Eola Park'
            description='A swan-filled lake in the heart of downtown Orlando.'
            websiteUrl='https://www.orlando.gov/Parks-the-Environment/Directory/Lake-Eola-Park'
            featuredImageSrc={lakeEolaPark}
            distanceFromVenue='16 miles'
          />
        </li>
      </ul>
    </div>
  )
}
