import { RouteComponentProps } from 'react-router'
import zolaLogo from '../../../../assets/zola-logo.png'
import './Registry.scss'

const registries = [
  {
    name: 'Zola',
    url: 'https://www.zola.com/registry/eshaanddaniel',
    imageUrl: zolaLogo,
  },
]

export function Registry(_props: RouteComponentProps) {
  return <div id='registry-container'>
    <h2>Registry</h2>
    {
      registries.map(({ name, url, imageUrl }) =>
        <div className='card registry-card'>
          <a href={url}
            target='_blank'
            rel='noopener noreferrer'>

            <h3 className='registry-card--title'>
              {name}
            </h3>

            <div className='registry-card--image'
              style={{ backgroundImage: 'url(' + imageUrl + ')' }}>
            </div>

            <div className='registry-card--cta'>
              <span className='label'>
                Visit this registry <span>↗</span>
              </span>
            </div>
          </a>
        </div>
      )
    }
  </div>
}
