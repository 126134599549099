import { RouteComponentProps } from 'react-router-dom'
import balcony from '../../../../assets/balcony-bw.jpg'
import './TheDayOf.scss'

export default function(_props: RouteComponentProps)
{
  return (
    <div id='the-day-of-container'>

      <div className='the-day-of-card card'>
        <div className='the-day-of-card--header card--header'
          style={{
            backgroundImage: `url(${balcony})`,
            backgroundPosition: 'bottom center',
          }}>
        </div>

        <div>
          <h2>The Day Of</h2>

          <p>
            <a href='#what-should-i-wear'
              style={{ textDecoration: 'underline' }}>
              <button><span>What should I wear?</span></button>
            </a>
          </p>

          <p>All events will be outdoors at Paradise Cove, with dinner served under a covered pavilion.</p>

          <p>
            <a href='https://goo.gl/maps/SS8bp8A3zf62Xu4m9'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'underline' }}>
              Paradise Cove<br />
              13245 Lake Bryan Dr<br />
              Orlando, FL 32821
            </a>
          </p>
          
          <h3><strong>3:00 PM:</strong> Guest arrival</h3>
          <p>
            Please plan to arrive between 3:00 and 3:20 so you don't miss the groom's entrance! Light snacks and beverages will be served.
          </p>

          <h3><strong>3:30 PM:</strong> Baraat</h3>
          <p>
            The groom will enter in a musical procession. Get ready to dance along as the Shanbhogues welcome the Mayers into their family.
          </p>

          <h3><strong>3:45 PM:</strong> Seating</h3>
          <p>
            Guests will take their seats before the ceremony begins.
          </p>

          <h3><strong>4:00 PM:</strong> Ceremony</h3>
          <p>
            Danny and Esha will be married in a Jewish-Konkani fusion ceremony. We ask that phones and cameras be put away during the ceremony.
          </p>

          <h3><strong>5:00 PM:</strong> Cocktail Hour</h3>
          <p>
            Enjoy drinks and assorted appetizers as the sun sets over the lake.
          </p>

          <h3><strong>6:00 PM:</strong> Performances &amp; Entertainment</h3>
          <p>
            Take your seats as the couple's family and friends perform.
          </p>

          <h3><strong>6:30 PM:</strong> Dinner &amp; Dancing</h3>
          <p>
            Eat, drink, and dance the night away!
          </p>

          <h3><strong>11:00 PM:</strong> Sendoff</h3>
          <p>
            Gather on the dock as the couple makes their grand exit.
          </p>

          <div id="what-should-i-wear">
            <h2>What should I wear?</h2>
            
            <p>
              <strong>Great question!</strong>
            </p>
            <p>
              The events will be black tie optional. The bride will be wearing a white dress
              for the ceremony and a lehenga for the reception. The wedding party will be wearing
              a mix between formal Indian attire (saris) and formal Western attire (suits).
              Florida can get quite warm, so please dress to be comfortable outdoors.
            </p>
            <p>
              We would love to see you in your most colorful and festive outfits!
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}
