import { kebabCase } from 'lodash'
import { ChangeEvent } from 'react'
import { OrlandoInvitee } from '../../../rpc/InviteeModel'

export interface ActiveRsvpHotelProps
{
  invitee: OrlandoInvitee
  index: number
  onChange: (event: ChangeEvent<HTMLInputElement>) => any
  hotelName: string
  websiteUrl: string
}

export default function(props: ActiveRsvpHotelProps)
{
  const hotelName = props.hotelName || 'Somewhere Else/Not Sure Yet'
  return <div className={'hotel-pill hotel-pill--' + kebabCase(hotelName)}>
    <label>
      <input type='radio'
        id={kebabCase(hotelName) + '-attending-checkbox--' + props.index}
        className='hotel-pill--checkbox'
        checked={!!props.hotelName ? props.invitee.expectsToStayAt === props.hotelName : !props.invitee.expectsToStayAt}
        onChange={(event) => props.onChange(event)}
      />

      <span className='hotel-pill--label-text'>
        <span className='hotel-pill--title'>
          {hotelName}
        </span>
        {
          // props.eventNameClosestTo &&
          // <span className='hotel-pill--subtitle'>
          //   closest to {props.eventNameClosestTo}
          // </span>
        }
      </span>
    </label>

    {
      // props.websiteUrl &&
      // <a className='hotel-pill--link'
      //   href={props.websiteUrl}
      //   target='_blank'
      //   rel='noopener noreferrer'>
      //   Visit website ↗
      // </a>
    }
  </div>
}
