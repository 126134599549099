import { TinyLink } from '../TinyLink'
import RecommendedBadge from './RecommendedBadge'

export interface HotelProps
{
  name: string
  featuredImageSrc?: string
  websiteUrl: string
  dollarSigns: 1|2|3|4
  overview: string
  address: string
  roomBlockInstructions: string
  recommended?: boolean
  /** In minutes. */
  driveTimeToWelcomeCocktails?: number
  /** In minutes. */
  driveTimeToCeremony?: number
  /** In minutes. */
  driveTimeToReception?: number
}

function createDollarsArray(length: number): string[]
{
  const newArr: string[] = []
  for (let i = length; i > 0; i--) newArr.push('$')
  return newArr
}

function createDollarsDescription(length: number): string
{
  switch (length) {
    case 1:
      return 'One dollar sign: quite inexpensive'
    case 2:
      return 'Two dollar signs: moderately inexpensive'
    case 3:
      return 'Three dollar signs: moderately expensive'
    case 4:
      return 'Four dollar signs: quite expensive'
    default:
      return 'Pricing information not available'
  }
}

export function Hotel(props: HotelProps)
{
  return <div className='card hotel-card'>
    <div className='card--header hotel-card--header'
      style={{
        backgroundImage: 'url(' + props.featuredImageSrc + ')',
      }}>
      <div className='hotel-card--header--top'>
        {
          props.recommended &&
          <div className='hotel-card--header--recommended label'>
            <RecommendedBadge />
            &nbsp;&nbsp;
            <small>Recommended by us!</small>
          </div>
        }
        <TinyLink to={props.websiteUrl}
          altStyle={true}>
          Visit website <span>↗</span>
        </TinyLink>
      </div>
      <div className='hotel-card--header--main'>
        <h3 className='h3-alt'>{props.name}</h3>
        <div className='hotel-card--header--dollars'>
          {createDollarsArray(props.dollarSigns)}
          <p className='sr-only'>
            {createDollarsDescription(props.dollarSigns)}
          </p>
        </div>
      </div>
    </div>
    <div className='hotel-card--overview'>
      <p>{props.overview}</p>
    </div>
    <div className='hotel-card--main'>
      <div className='label-group'>
        <div className='label'>Address</div>
        <div className='value'>{props.address}</div>
      </div>
      <div className='label-group'>
        <div className='label'>Discounted room block?</div>
        <div className='value'>
          <a href={props.websiteUrl}
            target='_blank'
            rel='noopener noreferrer'>
            {props.roomBlockInstructions ?? 'No'}
          </a>
        </div>
      </div>
      {/* <div className='label-group'>
        <div className='label'>Proximity (driving)</div>
        <div className='value'>
          <p>{props.driveTimeToWelcomeCocktails} minutes to Welcome Cocktails (Reunion)</p>
          <p>{props.driveTimeToCeremony} minutes to Ceremony (Paradise Cove)</p>
          <p>{props.driveTimeToReception} minutes to Reception (1010 West)</p>
        </div>
      </div> */}
    </div>
  </div>
}
