import { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { Observable } from 'rxjs'
import { AppAction } from './AppActions'
import { OrlandoRsvp } from './rpc/RsvpModel'

export interface AppState
{
  screenIsNarrow: boolean
  activeOrlandoRsvp?: OrlandoRsvp
  lastActiveOrlandoRsvp?: OrlandoRsvp
  orlandoRsvpNameSearchAutofill?: string
  orlandoRsvpButtonIsAnimating?: boolean
  activeOrlandoRsvpIsAnimating?: boolean
  toast?: AppToast
}

export const createInitialAppState: () => AppState = () => ({
  screenIsNarrow: window.innerWidth < 900,
  activeOrlandoRsvp: undefined,
  lastActiveOrlandoRsvp: undefined,
  orlandoRsvpNameSearchAutofill: undefined,
  orlandoRsvpButtonIsAnimating: undefined,
  activeOrlandoRsvpIsAnimating: undefined,
  toast: undefined
})

export interface AppStateAwareProps
{
  store: Observable<AppState>
  dispatch: (action: AppAction) => void
}

export type AppStateAwareRouteComponentProps = RouteComponentProps & AppStateAwareProps

export class AppStateAwareComponent<StateType = any, PropsType = AppStateAwareProps>
       extends Component<PropsType, StateType> { }

export class AppStateAwareRouteComponent<StateType = any, PropsType = AppStateAwareRouteComponentProps>
       extends Component<PropsType, StateType> { }

export enum AppToastType
{
  INFO = 'info',
  SUCCESS = 'success',
  DANGER = 'danger'
}

export class AppToast
{
  constructor(
    public type: AppToastType,
    public message: string,
  ) { }
}
