export function isAttending(
  eventsAttending: { [key: string]: boolean },
  eventKey?: 'welcomeCocktails'|'ceremony'|'reception'): boolean {
  if (!!eventKey) {
    return eventsAttending[eventKey]
  }
  return Object.keys(eventsAttending).some(
    (_eventKey) => eventsAttending[_eventKey]
  )
}
