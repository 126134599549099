import { values } from 'lodash'
import { OrlandoRsvp } from '../rpc/RsvpModel'

export function getAttendingCount(rsvp: OrlandoRsvp): number
{
  return values(rsvp.invitees)
    .filter(({ eventsAttending }) => eventsAttending && Object.keys(eventsAttending).some(
      (eventKey) => eventsAttending[eventKey as 'welcomeCocktails'|'ceremony'|'reception']
    ))
    .length
}
