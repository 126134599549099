// import tenTenWest from '../../../../assets/1010-west-lamp.jpg'
import eventsMap from '../../../../assets/events-map.jpg'
// import paradiseCove from '../../../../assets/paradise-cove.jpg'
// import reunionHouse from '../../../../assets/reunion-house.jpg'
// import events from '../../../../data/orlando-events'
import { AppStateAwareRouteComponent } from '../../../AppModel'
// import { EventDetail } from './EventDetail'
import './EventDetails.scss'

export class EventDetails extends AppStateAwareRouteComponent
{
  public render()
  {
    return <div id='event-details-container'>
      <h2>Events</h2>
      <ul id='event-details-list' className='list-unstyled'>
        {/* <li>
          <EventDetail
            name={events.welcomeCocktails.name}
            venueName={events.welcomeCocktails.venueName}
            featuredImageSrc={reunionHouse}
            date={events.welcomeCocktails.date}
            address={events.welcomeCocktails.address}
            attire={events.welcomeCocktails.attire}
            food={events.welcomeCocktails.food}
            overview={events.welcomeCocktails.overview}
            parking={events.welcomeCocktails.parking}
          />
        </li>
        <li>
          <EventDetail
            name={events.ceremony.name}
            venueName={events.ceremony.venueName}
            featuredImageSrc={paradiseCove}
            date={events.ceremony.date}
            address={events.ceremony.address}
            attire={events.ceremony.attire}
            food={events.ceremony.food}
            overview={events.ceremony.overview}
            parking={events.ceremony.parking}
          />
        </li>
        <li>
          <EventDetail
            name={events.reception.name}
            venueName={events.reception.venueName}
            featuredImageSrc={tenTenWest}
            date={events.reception.date}
            address={events.reception.address}
            attire={events.reception.attire}
            food={events.reception.food}
            overview={events.reception.overview}
            parking={events.reception.parking}
          />
        </li> */}
      </ul>

      <div className='map'>
        <div className='card'>
          <a href='https://drive.google.com/open?id=1-uYqEqQcOU0TZ1xcAq44RxxjFB8BZw8h&usp=sharing'
            target='_blank'
            rel='noopener noreferrer'>

            <div className='map--main'
              style={{
                backgroundImage: 'url(' + eventsMap + ')',
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                height: '400px',
              }}>
            </div>

            <div className='map--footer'>
              <span className='label'>
                Click to view an interactive map of our events
                <span>&nbsp;&nbsp;↗</span>
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  }
}
