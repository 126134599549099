import { debounce, values } from 'lodash'
import { ChangeEvent } from 'react'
import { distinctUntilKeyChanged } from 'rxjs/operators'
import { ActiveOrlandoRsvpUpdate, OrlandoRsvpClose, ToastEnqueue } from '../../../AppActions'
import { AppStateAwareComponent, AppStateAwareProps, AppToast, AppToastType } from '../../../AppModel'
import { emailAddressIsValid } from '../../../helpers/emailAddressIsValid'
import { forLifeOf } from '../../../helpers/forLifeOf'
import { getColloquialPartyName } from '../../../helpers/getColloquialPartyName'
import { getOrAssumePrimary } from '../../../helpers/getOrAssumePrimary'
import { isAttending } from '../../../helpers/isAttending'
import { MortalityAware } from '../../../helpers/MortalityAware'
import { vaccinationDateIsValid } from '../../../helpers/vaccinationDateIsValid'
import { copyableInviteeProps, OrlandoInvitee } from '../../../rpc/InviteeModel'
import { sendOrlandoRsvpEmail } from '../../../rpc/OrlandoRsvpActions'
import { OrlandoRsvp } from '../../../rpc/RsvpModel'
import './ActiveRsvp.scss'
import { ActiveRsvpInviteeCard } from './ActiveRsvpInviteeCard'

export interface ActiveRsvpState
{
  rsvpButtonIsAnimating?: boolean
  scrollY: number
  isSubmitting: boolean
}

export interface ActiveRsvpProps extends AppStateAwareProps
{
  rsvp: OrlandoRsvp
}

@MortalityAware()
export default class ActiveRsvp extends AppStateAwareComponent<ActiveRsvpState, ActiveRsvpProps>
{
  public state: ActiveRsvpState = {
    rsvpButtonIsAnimating: true,
    scrollY: this._getScrollY(),
    isSubmitting: false,
  }

  public componentDidMount(): void
  {
    this.props.store
      .pipe(forLifeOf(this), distinctUntilKeyChanged('orlandoRsvpButtonIsAnimating'))
      .subscribe(({ orlandoRsvpButtonIsAnimating: rsvpButtonIsAnimating }) =>
        this.setState({ rsvpButtonIsAnimating }))

    setTimeout(() => {
      const focusTarget = document.getElementById('active-rsvp--close-btn')
      if (focusTarget) {
        focusTarget.focus()
      }
    })
  }

  public render()
  {
    const primary = getOrAssumePrimary(this.props.rsvp)

    return <div id='active-rsvp'
      className={!this.state.rsvpButtonIsAnimating ? 'in-full-view' : ''}
      style={{
        transform: `translateY(${this.state.scrollY}px)`
      }}
      onClick={() => this._handleRsvpClose()}>

      <div id='active-rsvp--container'
        onClick={(event) => {
          event.stopPropagation()
          return false
        }}>
        <button id='active-rsvp--close-btn'
          onClick={() => this._handleRsvpClose()}>
          <span id='active-rsvp--close-btn--icon'>⟵</span>
          &nbsp;
          <span id='active-rsvp--close-btn--text'>Go Back</span>
        </button>

        <h2 id='active-rsvp--title' className='dark-text-shadow'>
          { getColloquialPartyName(this.props.rsvp) }
          {/*primary.lastName*/}
          {/*<small>Party</small>*/}
        </h2>

        <p className='dark-text-shadow'>
          The party is back on for November 13<sup>th</sup>! We can't wait to see you there.
        </p>

        <form id='active-rsvp--form'
          onSubmit={(event) => event.stopPropagation()}>
          {values(this.props.rsvp.invitees)
            .sort((a) => a.isPrimary ? -1 : 0)
            .map((invitee, index) =>
              <ActiveRsvpInviteeCard key={index}
                store={this.props.store}
                dispatch={this.props.dispatch}
                rsvp={this.props.rsvp}
                invitee={invitee}
                index={index}
                handleIsAttendingChange={this._handleIsAttendingChange.bind(this)}
                handleEventAttendingChange={this._handleEventAttendingChange.bind(this)}
                handleHotelExpectedChange={this._handleHotelExpectedChange.bind(this)}
                handleInviteeChange={this._handleInviteeChange.bind(this)}
                handleApplyToAll={this._handleApplyToAll.bind(this)}
              />
            )}

          <div id='active-rsvp--form--comments'
            className='card'>
            <div className='input-group'>
              <label className='label'
                htmlFor='active-rsvp--form--comments--label'>
                Please leave questions or comments here
              </label>
              <textarea id='active-rsvp--form--comments--input'
                value={this.props.rsvp.message}
                onChange={({ target }) => {
                  this._handleRsvpChange({ message: target.value })
                }}>
              </textarea>
            </div>
          </div>

          <div id='active-rsvp--form--submit'>
            {
              !primary.emailAddress &&
              <div id='active-rsvp--form--errors'>
                Please provide your email address, so we can send you a confirmation!
              </div>
            }
            <button
              type='button'
              disabled={this._submitShouldBeDisabled(primary)}
              onClick={() => this._handleFormSubmit()}>
              <span>{
                !!this.props.rsvp.dateSubmitted
                  ? 'Save'
                  : 'Submit'
              }</span>
              <span className='arrow'>⟶</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  }

  private _submitShouldBeDisabled(primaryInvitee: OrlandoInvitee): boolean {

    const invitees = values(this.props.rsvp.invitees)

    for (const invitee of invitees) {
      if (isAttending(invitee.eventsAttending) && !vaccinationDateIsValid(invitee)) {
        return true
      }
    }

    return !emailAddressIsValid(primaryInvitee.emailAddress) ||
      !allGuestsHaveAName(invitees) ||
      this.state.isSubmitting
  }

  private async _handleFormSubmit(): Promise<void>
  {
    this.setState({ isSubmitting: true })
    // Allow blurs to happen.
    setTimeout(async () => {
      const wasSubmitted = !!this.props.rsvp.dateSubmitted
      if (!wasSubmitted) {
        this._handleRsvpChange({ dateSubmitted: new Date().toISOString() })
      }
      this._handleRsvpChange({ dateUpdated: new Date().toISOString() })
      await sendOrlandoRsvpEmail(this.props.rsvp)
      this._handleRsvpClose()
      this.props.dispatch(new ToastEnqueue(new AppToast(
        AppToastType.SUCCESS,
        wasSubmitted ? 'RSVP updated!' : 'RSVP sent! You can always come back later to edit it.'
      )))
      this.setState({ isSubmitting: false })
    })
  }

  private _handleRsvpClose(): void
  {
    this.props.dispatch(new OrlandoRsvpClose(this.props.rsvp))
  }

  private async _handleRsvpChange(update: Partial<OrlandoRsvp>): Promise<void>
  {
    this.props.dispatch(new ActiveOrlandoRsvpUpdate(update))
  }

  private _handleInviteeChange(inviteeId: string, update: Partial<OrlandoInvitee>): void
  {
    debounce(() => {
      const invitees = { ...this.props.rsvp.invitees }
      invitees[inviteeId] = {
        ...invitees[inviteeId],
        ...update,
      }
      this.props.dispatch(new ActiveOrlandoRsvpUpdate({ invitees }))
    }, 1000, { leading: true, trailing: true })()
  }

  private _handleEventAttendingChange(
    event: ChangeEvent<HTMLInputElement>,
    inviteeId: string,
    eventKey: 'welcomeCocktails'|'ceremony'|'reception'
  ): void
  {
    const attending = event.target.checked
    const eventsAttending = {
      ...this.props.rsvp.invitees[inviteeId].eventsAttending,
      [eventKey]: attending
    }
    this._handleInviteeChange(inviteeId, { eventsAttending })
  }

  private _handleHotelExpectedChange(
    event: ChangeEvent<HTMLInputElement>,
    inviteeId: string,
    hotelName: string
  ): void
  {
    if (event.target.checked) {
      this._handleInviteeChange(inviteeId, { expectsToStayAt: hotelName })
    }
  }

  private _handleIsAttendingChange(
    inviteeId: string,
    _isAttending: boolean,
  ): void
  {
    this._handleInviteeChange(inviteeId, {
      eventsAttending: {
        welcomeCocktails: _isAttending,
        ceremony: _isAttending,
        reception: _isAttending,
      }
    })
  }

  private async _handleApplyToAll(templateInvitee: OrlandoInvitee): Promise<void>
  {
    Object.keys(this.props.rsvp.invitees)
      .filter((key) => key !== templateInvitee.id)
      .forEach((inviteeId) => {
        const inviteeUpdate = {} as Partial<OrlandoInvitee>
        copyableInviteeProps.forEach((key) => {
          inviteeUpdate[key] = templateInvitee[key] as any
        })
        setTimeout(() =>
        {
          this._handleInviteeChange(inviteeId, inviteeUpdate)
        })
      })
  }

  private _getScrollY(): number
  {
    const orlandoContainer = document.getElementById('orlando-container')
    return +(
      (orlandoContainer && orlandoContainer.scrollTop) ||
      window.pageYOffset || window.scrollY ||
      (document.documentElement || document.body.parentNode || document.body).scrollTop
    )
  }
}

function allGuestsHaveAName(invitees: OrlandoInvitee[]): boolean
{
  const inviteesAttending = invitees.filter((invitee) => isAttending(invitee.eventsAttending))
  return inviteesAttending.every((invitee) => !!invitee.firstName || !!invitee.lastName)
}
