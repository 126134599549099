import { AppStateAwareProps } from '../../../AppModel'
import { BangaloreInvitee } from '../../../rpc/InviteeModel'
import { BangaloreRsvp } from '../../../rpc/RsvpModel'

export interface RsvpFormProps extends AppStateAwareProps
{
  sharedState: RsvpFormSharedState
  openAsOverlay: boolean
  handleOpen: () => void
  handleClose: () => void
  handleUpdate: (partialRsvpFormState: Partial<RsvpFormSharedState>) => void
}

export class RsvpFormSharedState
{
  public rsvp = new BangaloreRsvp()
  public formWasSubmitted = false
  public formSubmitWasSuccessful = false
  public screenIsNarrow = false
}

export class RsvpFormLocalState
{
  public formIsOpen = false
  public isFadingOut = false
}

export interface RsvpFormInviteeProps
{
  index: number
  invitee: BangaloreInvitee
  handleUpdate: (invitee: BangaloreInvitee) => void
  handleRemove: () => void
}
