import { TinyLink } from '../TinyLink'

export interface ThingToDoProps
{
  name: string
  description: string
  featuredImageSrc: string
  websiteUrl: string
  distanceFromVenue: string
}

export function ThingToDo(props: ThingToDoProps)
{
  return <div className='card thing-to-do-card'>
    <div className='card--header thing-to-do-card--header'
      style={{
        backgroundImage: 'url(' + props.featuredImageSrc + ')',
      }}>
      <div className='thing-to-do-card--header--top'>
        <TinyLink to={props.websiteUrl}
          altStyle={true}>
          Visit website <span>↗</span>
        </TinyLink>
      </div>
      <div className='thing-to-do-card--header--main'>
        <h3 className='h3-alt'>{props.name}</h3>
      </div>
    </div>
    <div className='thing-to-do-card--description'>
      <p>{props.description}</p>
    </div>
    <div className='thing-to-do-card--main'>
      <div className='label-group'>
        <div className='label'>Distance from venue</div>
        <div className='value'>
          {props.distanceFromVenue}
        </div>
      </div>
      <div className='label-group'>
        <div className='label'>Website</div>
        <div className='value' style={{textDecoration: 'underline'}}>
          <a href={props.websiteUrl} rel='noopener noreferrer' target='_blank'>{props.websiteUrl}</a>
        </div>
      </div>
    </div>
  </div>
}
