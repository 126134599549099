import { values } from 'lodash'
import { OrlandoInvitee } from '../rpc/InviteeModel'
import { OrlandoRsvp } from '../rpc/RsvpModel'

export function getOrAssumePrimary(rsvp: OrlandoRsvp): OrlandoInvitee
{
  const invitees = values(rsvp.invitees)
  let primary = invitees.find(
    ({ isPrimary }) => !!isPrimary
  ) as OrlandoInvitee

  if (!primary) {
    primary = invitees[0]
  }

  return primary
}
