import { Component } from 'react'
import image1 from '../../../../assets/about-carousel-1-frame.jpg'
import image2 from '../../../../assets/about-carousel-2-miami.jpg'
import image4 from '../../../../assets/about-carousel-4-water.jpg'
import image5 from '../../../../assets/about-carousel-5-notre-dame.jpg'
import image6 from '../../../../assets/about-carousel-6-water-kiss.jpg'
import image7 from '../../../../assets/about-carousel-7-india.jpg'
import './AboutCarousel.scss'

export const ANIMATION_DURATION_MS = 400

export interface AboutCarouselState
{
  isAnimating: boolean,
  directionAnimating: 'LTR' | 'RTL' | undefined,
  incomingOrActiveImageUrl: string,
  outgoingImageUrl: string | undefined,
}

export default class AboutCarousel extends Component<{}, AboutCarouselState>
{
  private _imageUrls = [
    image4,
    image2,
    image1,
    image5,
    image6,
    image7
  ]
  public state: AboutCarouselState = {
    isAnimating: false,
    directionAnimating: undefined,
    incomingOrActiveImageUrl: this._imageUrls[0],
    outgoingImageUrl: this._imageUrls[1],
  }

  public render()
  {
    return (
      <div className='about-carousel'>
        <div className='preloader' style={{
          overflow: 'hidden',
          height: '0',
          width: '0',
          opacity: '0',
          position: 'absolute',
          zIndex: -1,
        }}>
          {
            this._imageUrls.map((imageUrl, index) => <img key={index} src={imageUrl} alt='preload' />)
          }
        </div>
        <div className='about-carousel--main'>
          {
            this.state.isAnimating
            ? (
              <div className={
                'animating-carousel ' +
                this._animationDirectionClassName(this.state.directionAnimating)
              }>
                {
                  this.state.directionAnimating === 'LTR' &&
                  <div className='animating-carousel--incoming-ltr carousel-img'
                    style={{ backgroundImage: `url(${this.state.incomingOrActiveImageUrl})` }}>
                  </div>
                }
                <div className='animating-carousel--outgoing carousel-img'
                  style={{ backgroundImage: `url(${this.state.outgoingImageUrl})` }}>
                </div>
                {
                  this.state.directionAnimating === 'RTL' &&
                  <div className='animating-carousel--incoming-rtl carousel-img'
                    style={{ backgroundImage: `url(${this.state.incomingOrActiveImageUrl})` }}>
                  </div>
                }
              </div>
            )
            : (
              <div className='static-carousel'>
                <div className='static-carousel--inner carousel-img'
                  style={{ backgroundImage: `url(${this.state.incomingOrActiveImageUrl})` }}>
                </div>
              </div>
            )
          }
        </div>
        <div className='about-carousel--controls'>
          <button
            onClick={() => this.animate('LTR')}>
            <span>⟵</span>
          </button>
          <button
            onClick={() => this.animate('RTL')}>
            <span>⟶</span>
          </button>
        </div>
      </div>
    )
  }

  public animate(direction: 'LTR'|'RTL'): void
  {
    const indexOfCurrent = this._getIndexOfImage(this.state.incomingOrActiveImageUrl)
    const indexOfPrevious = this._getIndexOfPreviousImage(this.state.incomingOrActiveImageUrl)
    const indexOfNext = this._getIndexOfNextImage(this.state.incomingOrActiveImageUrl)
    const currentImage = this._imageUrls[indexOfCurrent]
    let previousImage = this._imageUrls[indexOfPrevious]
    let nextImage = this._imageUrls[indexOfNext]

    if (direction === 'LTR' && this._isAllTheWayLtr())
    {
      previousImage = this._imageUrls[this._imageUrls.length - 1]
    }
    if (direction === 'RTL' && this._isAllTheWayRtl())
    {
      nextImage = this._imageUrls[0]
    }
    if (this.state.isAnimating)
    {
      return
    }

    this.setState({
      isAnimating: true,
      directionAnimating: direction,
      incomingOrActiveImageUrl: direction === 'LTR' ? previousImage : nextImage,
      outgoingImageUrl: currentImage,
    })

    setTimeout(
      () => this.setState({
        isAnimating: false,
        directionAnimating: undefined,
      }),
      ANIMATION_DURATION_MS,
    )
  }

  private _isAllTheWayLtr(): boolean
  {
    return this._getIndexOfPreviousImage(this.state.incomingOrActiveImageUrl) === -1
  }

  private _isAllTheWayRtl(): boolean
  {
    return this._getIndexOfNextImage(this.state.incomingOrActiveImageUrl) === this._imageUrls.length
  }

  private _animationDirectionClassName(direction: 'LTR' | 'RTL' | undefined): string
  {
    return !!direction ? 'direction-' + direction.toLowerCase() : ''
  }

  private _getIndexOfImage(imageUrl: string | undefined): number
  {
    return this._imageUrls.indexOf(imageUrl as string)
  }

  private _getIndexOfPreviousImage(imageUrl: string | undefined): number
  {
    return this._getIndexOfImage(imageUrl) - 1
  }

  private _getIndexOfNextImage(imageUrl: string | undefined): number
  {
    return this._getIndexOfImage(imageUrl) + 1
  }
}
