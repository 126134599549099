import { getOrlandoInviteesLength, OrlandoRsvp } from '../../../rpc/RsvpModel'

export interface RsvpSearchResultsProps
{
  activeRsvp?: OrlandoRsvp
  rsvps: OrlandoRsvp[]
  handleRsvpSelect(rsvp: OrlandoRsvp): void
}

export function RsvpSearchResults({ activeRsvp, rsvps, handleRsvpSelect }: RsvpSearchResultsProps)
{
  return (
    <ul id='rsvp-search--results'>{
      rsvps.map((rsvp, index) => getOrlandoInviteesLength(rsvp) > 0 &&
        <li key={index}
          className={activeRsvp && rsvp.id === activeRsvp.id ? 'is-active' : ''}
          onClick={() => handleRsvpSelect(rsvp)}>
          <div className='rsvp'>
            <div className='rsvp--title'>
              {rsvp.partyName}
              {
                !rsvp.partyName.match(/\sand\s/gi) && (!!rsvp.addressCity || !!rsvp.addressStateOrCountry) &&
                <small className='rsvp--subtitle'>{rsvp.addressCity || rsvp.addressStateOrCountry}</small>
              }
            </div>
            {/*
              !!rsvp.dateSubmitted && <div className='already-responded'>
                <i>✔</i> Responded
              </div>
            */}
          </div>
          <button className='button'
            aria-label={rsvp.partyName}
            type='button'>
            <span className='button--text'>That's {
              getOrlandoInviteesLength(rsvp) < 2 ? 'me' : 'us'
            }!</span>
          </button>
        </li>
      )
      .slice(0, 4)
    }</ul>
  )
}
