import { values } from 'lodash'
import { Redirect, Route, Switch } from 'react-router-dom'
import tenTenWest from '../../../assets/1010-west-lamp.jpg'
import orlandoBg from '../../../assets/bg-rollins-flipped-blur.jpg'
import blueHeron from '../../../assets/blue-heron.jpg'
import crownePlaza from '../../../assets/crowne-plaza.jpg'
import editRsvpBtnBg from '../../../assets/edit-rsvp-btn-bg-4x.png'
import logo from '../../../assets/logo-orlando.png'
import paradiseCove from '../../../assets/paradise-cove.jpg'
import reunionHouse from '../../../assets/reunion-house.jpg'
import thatsMeBtnBg from '../../../assets/thats-me-btn-bg-4x.png'
import { AppStateAwareRouteComponent, AppStateAwareRouteComponentProps } from '../../AppModel'
import AppToasts from '../../AppToasts'
import { forLifeOf } from '../../helpers/forLifeOf'
import { MortalityAware } from '../../helpers/MortalityAware'
import { streamOrlandoRsvps } from '../../rpc/OrlandoRsvpActions'
import { OrlandoRsvp } from '../../rpc/RsvpModel'
import About from './About/About'
import BookExchange from './BookExchange/BookExchange'
import { EventDetails } from './EventDetails/EventDetails'
import { Hotels } from './Hotels/Hotels'
import { LoginForm } from './LoginForm/LoginForm'
import './Orlando.scss'
import { OrlandoState } from './OrlandoModel'
import { OrlandoNavigation } from './OrlandoNavigation'
import { Registry } from './Registry/Registry'
import ActiveRsvp from './Rsvp/ActiveRsvp'
import Rsvp from './Rsvp/Rsvp'
import TheDayOf from './TheDayOf/TheDayOf'
import ThingsToDo from './ThingsToDo/ThingsToDo'

@MortalityAware()
export class Orlando extends AppStateAwareRouteComponent<OrlandoState>
{
  public state = new OrlandoState()
  private _tearDownOrlandoRsvps?: () => void

  private _isLoggedIn(): boolean
  {
    return true
    // const parsedCookies = document.cookie.split(';')
    //   .filter(keyValuePair => !!keyValuePair)
    //   .map((keyValuePair) => keyValuePair.split('='))
    // const isLoggedInCookie = parsedCookies.find((cookie) => cookie[0] === ORLANDO_IS_LOGGED_IN)
    // return isLoggedInCookie ? isLoggedInCookie[1] === 'true' : false
  }

  constructor(props: AppStateAwareRouteComponentProps)
  {
    super(props)
    this.state.isLoggedIn = this._isLoggedIn()
  }

  public componentDidMount(): void
  {
    const { rsvps$, tearDown } = streamOrlandoRsvps()
    this._tearDownOrlandoRsvps = tearDown
    rsvps$
      .pipe(forLifeOf(this))
      .subscribe(
        (rsvps) => this.setState({ rsvps: values(rsvps) })
      )

    this.props.store
      .pipe(forLifeOf(this))
      .subscribe(({
        activeOrlandoRsvp: activeRsvp,
        lastActiveOrlandoRsvp: lastActiveRsvp,
        activeOrlandoRsvpIsAnimating: activeRsvpIsAnimating,
        screenIsNarrow,
      }) =>
        this.setState({
          mastheadIsFocused: !activeRsvp,
          activeRsvp,
          lastActiveRsvp,
          activeRsvpIsAnimating,
          screenIsNarrow,
        }))
  }

  public componentWillUnmount(): void
  {
    if (this._tearDownOrlandoRsvps) this._tearDownOrlandoRsvps()
  }

  public render()
  {
    const { match } = this.props

    return <div id='orlando-container'
      style={{
        backgroundImage: 'url(' + orlandoBg + ')'
      }}>
      <div className='preload-images'>
        <img src={thatsMeBtnBg} alt='preloader' />
        <img src={editRsvpBtnBg} alt='preloader' />
        <img src={reunionHouse} alt='preloader' />
        <img src={paradiseCove} alt='preloader' />
        <img src={tenTenWest} alt='preloader' />
        <img src={blueHeron} alt='preloader' />
        <img src={crownePlaza} alt='preloader' />
      </div>

      <div id="orlando-footer">
        <p>If you have questions, fill out the RSVP form, or contact us directly at <strong>dannyandesha@gmail.com</strong>.</p>
      </div>

      <div id='orlando-inner'>
        <div id='orlando-masthead'
          className={this.getMastheadClassName(this.state)}>

          <div id='orlando-logo-container'>
            <img id='orlando-logo'
              alt='Orlando'
              src={logo}
            />
            {
              !this.state.screenIsNarrow &&
              <OrlandoNavigation {...this.props}
                isHidden={!this.state.isLoggedIn}
              />
            }
          </div>

          <h1>Orlando <span id='orlando-date'>NOV • 13 • 2021</span></h1>

          {
            this.state.screenIsNarrow &&
            <OrlandoNavigation {...this.props}
              isHidden={!this.state.isLoggedIn}
            />
          }

          <div id='orlando-h1-below'></div>
        </div>

        <div id='orlando-routes'
          aria-live='polite'>
            {
              this.state.isLoggedIn
              ? <Switch>
                {
                  <Route exact path={`${match.path}/rsvp`}
                    render={(props) => <Rsvp {...props}
                      rsvps={this.state.rsvps as OrlandoRsvp[]}
                      store={this.props.store}
                      dispatch={this.props.dispatch}
                    />}
                  />
                }
                <Route exact path={`${match.path}/about`}
                  render={(props) => <About
                    {...props}
                  />}
                />
                <Route exact path={`${match.path}/hotels`}
                  render={(props) => <Hotels
                    {...props}
                    store={this.props.store}
                    dispatch={this.props.dispatch}
                  />}
                />
                <Route exact path={`${match.path}/events`}
                  render={(props) => <EventDetails
                    {...props}
                    store={this.props.store}
                    dispatch={this.props.dispatch}
                  />}
                />
                <Route exact path={`${match.path}/registry`}
                  render={(props) => <Registry
                    {...props}
                  />}
                />
                <Route exact path={`${match.path}/book-exchange`}
                  render={(props) => <BookExchange
                    {...props}
                  />}
                />
                <Route exact path={`${match.path}/things-to-do`}
                  render={(props) => <ThingsToDo
                    {...props}
                  />}
                />
                <Route exact path={`${match.path}/the-day-of`}
                  render={(props) => <TheDayOf
                    {...props}
                  />}
                />
                <Redirect from={match.path} to={`${match.path}/rsvp`} />
                <Redirect from='*' to={match.path} />
              </Switch>
              : <Switch>
                <Route exact path={`${match.path}/login`}
                  component={LoginForm}
                />
                <Redirect from={match.path} to={`${match.path}/login`} />
              </Switch>
            }
        </div>
      </div>

      {/* <div id='orlando-announcement-banner'>
        <div id='orlando-announcement-banner-inner'>
          <strong>Coronavirus update:</strong>&nbsp;
          Because keeping you all safe is our top priority, <span>we have decided to postpone the festivities until 2021.</span> Please reach out to us directly if you have questions. We hope everyone is staying healthy and safe!
          ❤️ E&amp;D
        </div>
      </div> */}

      {
        this.state.isLoggedIn &&
        <div id='orlando-modals'>
          {
            (!!this.state.activeRsvp || this.state.activeRsvpIsAnimating) &&
            <ActiveRsvp
              rsvp={this.state.activeRsvp || this.state.lastActiveRsvp as OrlandoRsvp}
              store={this.props.store}
              dispatch={this.props.dispatch}
            />
          }
        </div>
      }

      {
        this.state.isLoggedIn &&
        <div id='orlando-toasts'>
          <AppToasts store={this.props.store}
            dispatch={this.props.dispatch}
          />
        </div>
      }
    </div>
  }

  public getMastheadClassName({ mastheadIsFocused }: OrlandoState): string
  {
    let className = 'orlando-masthead'
    if (mastheadIsFocused) {
      className += ' is-focused'
    }
    return className
  }
}
