abstract class Invitee {
  public id = Date.now().toString();
  public firstName = "";
  public lastName = "";
  public emailAddress = "";
  public isPrimary: boolean | null = null;
}

export class BangaloreInvitee extends Invitee {
  public eventsAttending = ["Day 1", "Day 2"];
}

export interface OrlandoInvitee extends Invitee {
  eventsAttending: {
    welcomeCocktails: boolean;
    ceremony: boolean;
    reception: boolean;
  };
  expectsToStayAt: string;
  dietaryRestrictions: string;
  isTwelveOrYounger: boolean;
  isFourOrYounger: boolean;
  isIneligibleForVaccine: boolean;
  isKnownToBeAdult?: boolean;
  nameIsUnknown: boolean;
  willRequireParking?: boolean;
  phoneNumber?: string;
  vaccinationDate?: string;
  bookExchange?: boolean;
}

export const copyableInviteeProps: (keyof OrlandoInvitee)[] = [
  "eventsAttending",
  "expectsToStayAt",
  "dietaryRestrictions",
  "bookExchange",
];
