import { KeyboardEvent } from 'react'
import { mapInputChangeToValue } from '../../../helpers/mapInputChangeToValue'
import { OrlandoRsvp } from '../../../rpc/RsvpModel'

export interface RsvpSearchFormProps
{
  activeRsvp?: OrlandoRsvp
  inputValue?: string
  handleLastNameChange(lastName: string): void
  handleKeyDown(event: KeyboardEvent): void
}

export function RsvpSearchForm(props: RsvpSearchFormProps)
{
  return (
    <div id='rsvp-search--form'
      className={
        !!props.activeRsvp ? 'rsvp-is-active' : ''
      }>
      <div id='rsvp-search--form--inner'>
        <input id='rsvp-search--form--name'
          type='text'
          autoComplete='off'
          value={props.inputValue}
          placeholder='Enter your full name, e.g. Esha Mayer'
          onKeyDown={(event) => props.handleKeyDown(event)}
          onChange={(event) => props.handleLastNameChange(mapInputChangeToValue(event))}
        />
      </div>
    </div>
  )
}
