import checkmarkWhite from '../../../../assets/checkmark-white.svg'
import {
    OrlandoRsvpClose, OrlandoRsvpForget, OrlandoRsvpSelect,
    OrlandoRsvpSelectOnReturn
} from '../../../AppActions'
import { AppStateAwareRouteComponent, AppStateAwareRouteComponentProps } from '../../../AppModel'
import { areSomeInviteesAttending } from '../../../helpers/areSomeInviteesAttending'
import { forLifeOf } from '../../../helpers/forLifeOf'
import { getAttendingCount } from '../../../helpers/getAttendingCount'
import { getColloquialPartyName } from '../../../helpers/getColloquialPartyName'
import { getOrAssumePrimary } from '../../../helpers/getOrAssumePrimary'
import { MortalityAware } from '../../../helpers/MortalityAware'
import { getRsvpIdForCurrentUser } from '../../../rpc/OrlandoRsvpActions'
import { OrlandoRsvp } from '../../../rpc/RsvpModel'
import { TinyLink } from '../TinyLink'
import './Rsvp.scss'
import RsvpSearch from './RsvpSearch'

class RsvpState
{
  public activeRsvp?: OrlandoRsvp
  public lastActiveRsvp?: OrlandoRsvp
  public rsvpButtonIsAnimating?: boolean
}

export interface RsvpProps extends AppStateAwareRouteComponentProps
{
  rsvps: OrlandoRsvp[]
}

@MortalityAware()
export default class Rsvp extends AppStateAwareRouteComponent<RsvpState, RsvpProps>
{
  public state = new RsvpState()

  public componentDidMount(): void
  {
    // Fetch last active Orlando RSVP.
    const lastActiveOrlandoRsvpId = getRsvpIdForCurrentUser()
    if (lastActiveOrlandoRsvpId)
    {
      this.props.dispatch(new OrlandoRsvpSelectOnReturn(lastActiveOrlandoRsvpId))
    }

    this.props.store
      .pipe(forLifeOf(this))
      .subscribe(({
        activeOrlandoRsvp: activeRsvp,
        lastActiveOrlandoRsvp: lastActiveRsvp,
        orlandoRsvpButtonIsAnimating: rsvpButtonIsAnimating,
      }) => this.setState({
        activeRsvp,
        lastActiveRsvp,
        rsvpButtonIsAnimating,
      }))
  }

  private _handleNotYouClick(): void
  {
    this.props.dispatch(new OrlandoRsvpClose(undefined))
    this.props.dispatch(new OrlandoRsvpForget())
  }

  private _handleEditClick(): void
  {
    this.props.dispatch(new OrlandoRsvpSelect((this.state.lastActiveRsvp as OrlandoRsvp).id))
  }

  public render()
  {
    const activeRsvp = this.state.activeRsvp
    const lastActiveRsvp = this.state.lastActiveRsvp as OrlandoRsvp
    return (
      <div id='rsvp-route' className={
        !!activeRsvp ? 'rsvp-is-active' : ''
      }>
        {
          (
            lastActiveRsvp && !!lastActiveRsvp.dateSubmitted
          ) &&
          <div id='rsvp-thank-you--container'>
            <h2 id='rsvp-thank-you--title' className='h2-small'>
              {
                areSomeInviteesAttending(lastActiveRsvp)
                ? (
                  Object.keys(lastActiveRsvp.invitees).length === 1
                  ? <span>
                    Can't wait to see you, {
                      getOrAssumePrimary(lastActiveRsvp).firstName
                    }!
                  </span>
                  : <span>
                    Can't wait to see you, {
                      getColloquialPartyName(lastActiveRsvp)
                    }!
                  </span>
                )
                : <span>
                  We'll miss you, {
                    getColloquialPartyName(lastActiveRsvp)
                  }!
                </span>
              }
            </h2>
            <div id='rsvp-thank-you--pill'
              className={activeRsvp && lastActiveRsvp.id === activeRsvp.id ? 'is-active' : ''}>
              <div className='rsvp'>
                <div className='rsvp--title'>
                  {areSomeInviteesAttending(lastActiveRsvp) && <img alt='' src={checkmarkWhite}/>}
                  {areSomeInviteesAttending(lastActiveRsvp)
                    ? <h3 style={{ marginLeft: '10px', display: 'inline' }}>
                      {getAttendingCount(lastActiveRsvp)} Attending
                    </h3>
                    : <h3 style={{ display: 'inline' }}>Not Attending</h3>
                  }
                </div>
                <TinyLink onClick={() => this._handleNotYouClick()}>
                  Not You?
                </TinyLink>
              </div>
              <button className='button'
                onClick={() => this._handleEditClick()}
                aria-label='Edit RSVP'>
                <span className='button--text'>Edit RSVP</span>
              </button>
            </div>
          </div>
        }
        {
          (
            !this.state.lastActiveRsvp || !this.state.lastActiveRsvp.dateSubmitted
          ) &&
          <RsvpSearch
            {...this.props}
            rsvps={this.props.rsvps}
            store={this.props.store}
            dispatch={this.props.dispatch}
          />
        }
      </div>
    )
  }
}
