import { PropsWithChildren } from 'react'

export interface TinyLinkProps
{
  to?: string
  style?: React.CSSProperties
  onClick?: React.EventHandler<React.MouseEvent>
  altStyle?: boolean
}

function hrefIsVoid(href: string): boolean
{
  return !href || href.indexOf('void(0)') > -1
}

export const TinyLink = (props: PropsWithChildren<TinyLinkProps>) => !props.to || hrefIsVoid(props.to)
  ? <button
    className={props.altStyle ? 'tiny-link-alt' : 'tiny-link'}
    style={props.style}
    onClick={props.onClick}>
    {props.children}
  </button>
  : <a href={props.to}
    target='_blank'
    rel='noopener noreferrer'
    className={props.altStyle ? 'tiny-link-alt' : 'tiny-link'}
    style={props.style}
    onClick={props.onClick}>
    {props.children}
  </a>
