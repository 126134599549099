import { takeUntil } from 'rxjs/operators'

export const forLifeOf = <ValueType>(instance: any) => {
  // Check to make sure the class is a component class.
  // http://prideparrot.com/blog/archive/2018/7/extending_component_decorator_angular_6
  // Check to make sure the class has the @MortalityAwareComponent decorator.
  const isMortalityAware = !!instance.willUnmount$

  if (!isMortalityAware) {
    throw new Error(`Tried to use forLifeOf inside ${instance.constructor.name}, which is not a @MortalityAware Component.`)
  }
  return takeUntil<ValueType>(instance.willUnmount$)
}
