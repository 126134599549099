import { OrlandoRsvp } from '../rpc/RsvpModel'

export function getColloquialPartyName(rsvp: OrlandoRsvp): string
{
  if (rsvp.partyName)
  {
    return rsvp.partyName.replace(/^the(\s+)/gi, '')
  }
  return rsvp.partyName || ''
}
