import { ActiveOrlandoRsvpUpdate, AppAction, OrlandoRsvpClose,
  OrlandoRsvpCloseAnimationKeyframe, OrlandoRsvpForget, OrlandoRsvpOpenAnimationEnd, OrlandoRsvpSelectOnReturnSuccess, OrlandoRsvpSelectSuccess, ToastEnqueue } from './AppActions'
import { AppState } from './AppModel'
import { getOrAssumePrimary } from './helpers/getOrAssumePrimary'
import { OrlandoRsvp } from './rpc/RsvpModel'

export function appReducer(state: AppState, action: AppAction): AppState
{
  let update: Partial<AppState> = {}

  if (action instanceof OrlandoRsvpSelectSuccess) {
    update = {
      activeOrlandoRsvp: action.payload,
      lastActiveOrlandoRsvp: action.payload,
      orlandoRsvpButtonIsAnimating: true,
      activeOrlandoRsvpIsAnimating: true,
    }
  }

  if (action instanceof ActiveOrlandoRsvpUpdate) {
    update = {
      activeOrlandoRsvp: {
        ...state.activeOrlandoRsvp,
        ...action.payload as OrlandoRsvp,
      }
    }
  }

  if (action instanceof OrlandoRsvpClose) {
    const rsvp = action.payload
    update = {
      activeOrlandoRsvp: undefined,
      lastActiveOrlandoRsvp: rsvp,
      orlandoRsvpButtonIsAnimating: !!rsvp,
      activeOrlandoRsvpIsAnimating: !!rsvp,
    }
  }

  if (action instanceof OrlandoRsvpForget) {
    update = {
      lastActiveOrlandoRsvp: undefined,
      orlandoRsvpNameSearchAutofill: '',
    }
  }

  if (action instanceof OrlandoRsvpSelectOnReturnSuccess) {
    const rsvp = action.payload
    const { firstName, lastName } = getOrAssumePrimary(rsvp)
    update = {
      lastActiveOrlandoRsvp: rsvp,
      orlandoRsvpNameSearchAutofill: `${firstName ? firstName + ' ' : ''}${lastName}`
    }
  }

  if (action instanceof ToastEnqueue) {
    update = {
      toast: action.payload
    }
  }

  if (action instanceof OrlandoRsvpOpenAnimationEnd) {
    update = {
      orlandoRsvpButtonIsAnimating: false,
      activeOrlandoRsvpIsAnimating: false,
    }
  }

  if (action instanceof OrlandoRsvpCloseAnimationKeyframe) {
    const frame = action.payload

    switch (frame) {
      case 2:
        update = {
          activeOrlandoRsvpIsAnimating: false
        }
        break
      case 3:
        update = {
          orlandoRsvpButtonIsAnimating: false
        }
    }
  }

  return {
    ...state,
    ...update,
  }
}
