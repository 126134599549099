import { of, timer } from 'rxjs'
import { concatMap, distinctUntilKeyChanged, switchMap, take } from 'rxjs/operators'
import { AppStateAwareComponent, AppToast } from './AppModel'
import { forLifeOf } from './helpers/forLifeOf'
import { MortalityAware } from './helpers/MortalityAware'

class AppToastsState
{
  public toast?: StatefulAppToast
}

class StatefulAppToast extends AppToast
{
  public animatingOut = false
}

const LIFESPAN = 8000
const ANIMATION_DURATION = 200

@MortalityAware()
export default class AppToasts extends AppStateAwareComponent
{
  public state = new AppToastsState()

  public componentDidMount(): void
  {
    this.props.store
      .pipe(
        forLifeOf(this),
        distinctUntilKeyChanged('toast'),
        concatMap(({ toast }) => !!toast
          ? timer(0, LIFESPAN - ANIMATION_DURATION).pipe(
              take(2),
              switchMap((n) => n === 0
                ? of(toast)
                : timer(0, ANIMATION_DURATION).pipe(
                    take(2),
                    switchMap((_n) => _n === 0
                      ? of({ ...toast, animatingOut: true })
                      : of(undefined)
                    ),
                  )
              ),
            )
          : of(undefined)
        ),
      )
      .subscribe((toast) => this.setState({ toast }))
  }

  public render()
  {
    return (
      !!this.state.toast &&
      <div className={this._getToastClassName(this.state.toast)} role='alert'>
        <h1>{this.state.toast.message}</h1>
      </div>
    )
  }

  private _getToastClassName(toast: StatefulAppToast): string
  {
    let className = `toast toast-type--${toast.type}`
    if (toast.animatingOut) {
      className += ` is-animating-out`
    }
    return className
  }
}
