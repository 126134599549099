import { Component } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { fromEvent, Subject } from 'rxjs'
import { debounceTime, distinctUntilKeyChanged, filter, map, scan, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators'
import { ActiveOrlandoRsvpUpdate, AppAction, OrlandoRsvpClose, OrlandoRsvpCloseAnimationKeyframe, OrlandoRsvpForget, OrlandoRsvpOpenAnimationEnd, OrlandoRsvpOpenAnimationStart, OrlandoRsvpSelect, OrlandoRsvpSelectOnReturn, OrlandoRsvpSelectOnReturnSuccess, OrlandoRsvpSelectSuccess, ScreenIsNarrowUpdate } from './AppActions'
import { createInitialAppState } from './AppModel'
import { appReducer } from './AppReducer'
import { Bangalore } from './components/Bangalore/Bangalore'
import { Orlando } from './components/Orlando/Orlando'
import { forLifeOf } from './helpers/forLifeOf'
import { MortalityAware } from './helpers/MortalityAware'
import { instanceOf } from './helpers/StateManagementHelpers'
import { getOrlandoRsvp, updateOrlandoRsvp, updateRsvpForCurrentUser } from './rpc/OrlandoRsvpActions'
import { OrlandoRsvp } from './rpc/RsvpModel'

@MortalityAware()
export default class App extends Component
{
  // Because I don't feel like going through the entire Redux setup, I've decided
  // to just roll my own Redux-style state management here inside <App> using RxJS.
  private _actions = new Subject<AppAction>()
  private _store = this._actions.pipe(
    scan(appReducer, createInitialAppState()),
    startWith(createInitialAppState()),
    shareReplay(1),
  )

  constructor(props: {})
  {
    super(props)
    // Listen for updates, apply side effects.
    this.registerSideEffects()
  }

  public dispatch = (update: AppAction) =>
  { // This MUST be a lambda.
    this._actions.next(update)
  }

  public registerSideEffects(): void
  {
    this._store
      .pipe(
        forLifeOf(this),
        distinctUntilKeyChanged('lastActiveOrlandoRsvp'),
        map(({ lastActiveOrlandoRsvp }) => lastActiveOrlandoRsvp),
        filter((lastActiveOrlandoRsvp) => !!lastActiveOrlandoRsvp),
      )
      .subscribe((lastActiveOrlandoRsvp) => {
        updateRsvpForCurrentUser(lastActiveOrlandoRsvp as OrlandoRsvp)
      })

    this._actions
      .pipe(
        forLifeOf(this),
        instanceOf(OrlandoRsvpSelect),
        switchMap(({ payload: rsvpId }) => getOrlandoRsvp(rsvpId))
      )
      .subscribe((rsvp) => this.dispatch(new OrlandoRsvpSelectSuccess(rsvp as OrlandoRsvp)))

    this._actions
      .pipe(
        forLifeOf(this),
        instanceOf(OrlandoRsvpSelectSuccess)
      )
      .subscribe(() => {
        this.dispatch(
          new OrlandoRsvpOpenAnimationStart()
        )
      })

    this._actions
      .pipe(
        forLifeOf(this),
        instanceOf(OrlandoRsvpOpenAnimationStart),
        debounceTime(200)
      )
      .subscribe(() => {
        this.dispatch(
          new OrlandoRsvpOpenAnimationEnd()
        )
      })

    this._actions
      .pipe(
        forLifeOf(this),
        instanceOf(OrlandoRsvpClose),
      )
      .subscribe(() => {
        this.dispatch(new OrlandoRsvpCloseAnimationKeyframe(1))
      })

    this._actions
      .pipe(
        forLifeOf(this),
        instanceOf(OrlandoRsvpCloseAnimationKeyframe),
        filter(({ payload: frame }) => frame === 1),
        debounceTime(200),
      )
      .subscribe(() => {
        this.dispatch(new OrlandoRsvpCloseAnimationKeyframe(2))
      })

    this._actions
      .pipe(
        forLifeOf(this),
        instanceOf(OrlandoRsvpCloseAnimationKeyframe),
        filter(({ payload: frame }) => frame === 2),
        debounceTime(200),
      )
      .subscribe(() => {
        this.dispatch(new OrlandoRsvpCloseAnimationKeyframe(3))
      })

    this._actions
      .pipe(
        forLifeOf(this),
        instanceOf(ActiveOrlandoRsvpUpdate),
        withLatestFrom(this._store.pipe(
          filter(({ activeOrlandoRsvp }) => !!activeOrlandoRsvp),
          map(({ activeOrlandoRsvp }) => activeOrlandoRsvp as OrlandoRsvp)
        ))
      )
      .subscribe(([{ payload }, { id }]) => updateOrlandoRsvp(id, payload))

    this._actions
      .pipe(
        forLifeOf(this),
        instanceOf(OrlandoRsvpSelectOnReturn),
        switchMap(({ payload }) => getOrlandoRsvp(payload)),
        filter((rsvp) => !!rsvp),
      )
      .subscribe((rsvp) => this.dispatch(new OrlandoRsvpSelectOnReturnSuccess(rsvp as OrlandoRsvp)))

    this._actions
        .pipe(
          forLifeOf(this),
          instanceOf(OrlandoRsvpForget),
        )
        .subscribe(() => updateRsvpForCurrentUser({} as OrlandoRsvp))
  }

  public async componentDidMount(): Promise<void>
  {
    // Listen for window resize.
    fromEvent<UIEvent>(window, 'resize')
      .pipe(forLifeOf(this))
      .subscribe(({ target }) => this.dispatch(
        new ScreenIsNarrowUpdate((target as Window).innerWidth < 940)
      ))
  }

  public render()
  {
    return (
      <div className='app-container'>
        <div className='routes-container'>
          <BrowserRouter>
            <Switch>
              <Redirect exact from='/' to='/orlando' />
              <Route exact path='/bangalore'
                render={
                  (props) => <Bangalore {...props}
                    store={this._store}
                    dispatch={this.dispatch}
                  />
                }
              />
              <Route path='/orlando'
                render={
                  (props) => <Orlando {...props}
                    store={this._store}
                    dispatch={this.dispatch}
                  />
                }
              />
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    )
  }
}
