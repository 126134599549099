import firebase from "firebase/app";
import "firebase/database";

firebase.initializeApp({
  apiKey: "AIzaSyCDotBgxtHT7YKqgqRi-LfpHxO1P8zqq0U",
  authDomain: "danny-and-esha.firebaseapp.com",
  databaseURL: "https://danny-and-esha.firebaseio.com",
  projectId: "danny-and-esha",
  storageBucket: "danny-and-esha.appspot.com",
  messagingSenderId: "482582487338",
});

export const bangaloreRsvpsRef = firebase.database().ref("rsvps");
export const orlandoRsvpsRef = firebase.database().ref("orlando-rsvps");
