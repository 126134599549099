import { RouteComponentProps } from 'react-router-dom'
import './BookExchange.scss'

export default function(_props: RouteComponentProps)
{
  return (
    <div id='book-exchange-container'>
      <div className='card book-exchange-card'>
        <div className='book-exchange-card--header card--header'
          style={{
            backgroundImage: `url("https://i.pinimg.com/564x/d9/db/f2/d9dbf2085f619b0c8a1f30583fa36a8d.jpg")`
          }}>
        </div>
        <div className='book-exchange-card--body card--body'>
          <h2>A Wedding Book Exchange</h2>

          <blockquote>
            “Books are the quietest and most constant of friends; they are the most accessible and wisest of counselors, and the most patient of teachers.”
            &nbsp;&nbsp;&nbsp;
            <span className='by-line'>– Charles W. Eliot</span>
          </blockquote>

          <p>We love books! Danny studied English at University of Michigan and Esha loves to devour a compelling fiction novel. So, we decided to do something a little unconventional for our wedding: a book exchange! We request that if you would like to participate in the book exchange, that you:</p>
          <ol>
            <li>Bring a copy of your favorite book (or a book you recommend) to leave at the wedding.</li>
            <li>Prepare a note for the next reader explaining why you love the book and place it inside the front cover.</li>
            <li>When the night is over, if you have brought a book, pick a different one and take it home with you!</li>
          </ol>
          <p>We hope you enjoy this idea! Please send us any questions you have by replying to your invitation email, or in the "comments" box when you submit your RSVP.</p>
        </div>
      </div>
    </div>
  )
}
