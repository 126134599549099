
export default function()
{
  return <>
    <svg xmlns='http://www.w3.org/2000/svg'
      version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 344.963 344.963'>
      <g>
        <path style={{fill: 'currentColor'}} d='M321.847,86.242l-40.026-23.11l-23.104-40.02h-46.213l-40.026-23.11l-40.026,23.11H86.239   l-23.11,40.026L23.11,86.242v46.213L0,172.481l23.11,40.026v46.213l40.026,23.11l23.11,40.026h46.213l40.02,23.104l40.026-23.11   h46.213l23.11-40.026l40.026-23.11v-46.213l23.11-40.026l-23.11-40.026V86.242H321.847z M156.911,243.075   c-3.216,3.216-7.453,4.779-11.671,4.72c-4.219,0.06-8.455-1.504-11.671-4.72l-50.444-50.444c-6.319-6.319-6.319-16.57,0-22.889   l13.354-13.354c6.319-6.319,16.57-6.319,22.889,0l25.872,25.872l80.344-80.35c6.319-6.319,16.57-6.319,22.889,0l13.354,13.354   c6.319,6.319,6.319,16.57,0,22.889L156.911,243.075z'/>
      </g>
    </svg>
  </>
}
