import { RouteComponentProps } from 'react-router-dom'
import './About.scss'
import AboutCarousel from './AboutCarousel'

export default function(_props: RouteComponentProps)
{
  return (
    <div id='about-container'>
      <AboutCarousel />

      <div className='card about-card'>
        <h2>We're getting married (again)!</h2>
        <p>Welcome to our wedding website&mdash;we're so glad you're here!</p>
        <p>It's true: we couldn't wait, and decided to legally tie the knot this past December. And now we're <em>more</em> than ready to celebrate it with you!</p>
        <p>
          After meeting in Ann Arbor in the summer of 2014 (for which we have the app Tinder to thank), we grew close over our love of food, travel, and Michigan lakes&mdash;Esha loves swimming and Danny loves sailing!
        </p>
        <p>
          Thinking back on the days since we met fills us with so much joy and gratitude. They've included adventures all over the world with our amazing families and friends, finding our wonderful pup, Azalea, and creating a home together in Orlando.
        </p>
        <p>
          We can't wait to promise each other the rest of our lives. We hope you can celebrate with us!
        </p>
      </div>
    </div>
  )
}
