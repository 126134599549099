import logo from '../../../assets/logo.png'
import { AppStateAwareRouteComponent } from '../../AppModel'
import { forLifeOf } from '../../helpers/forLifeOf'
import { getRsvpFormHeight } from '../../helpers/getRsvpFormHeight'
import { MortalityAware } from '../../helpers/MortalityAware'
import './Bangalore.scss'
import { BangaloreState } from './BangaloreModel'
// import RsvpForm from './RsvpForm/RsvpForm'
import { RsvpFormSharedState } from './RsvpForm/RsvpFormModel'

@MortalityAware()
export class Bangalore extends AppStateAwareRouteComponent<BangaloreState>
{
  public state = new BangaloreState()

  public handleRsvpFormUpdate = (partialRsvpFormState: Partial<RsvpFormSharedState>) =>
  {
    const rsvpFormState = { ...this.state.rsvpFormState, ...partialRsvpFormState }
    this.setState({ rsvpFormState })
  }

  public componentDidMount(): void
  {
    this.props.store
      .pipe(forLifeOf(this))
      .subscribe(({ screenIsNarrow }) => {
        this.setState({ screenIsNarrow })
      })
  }

  public renderRightCol()
  {
    return (
      <div className='column-container right'>
        <div className='column-inner right'>
          {this.renderDayTwo()}
          {this.renderDayOne()}
        </div>
      </div>
    )
  }

  public maybeRenderFormInRightCol()
  {
    // if (!this.state.screenIsNarrow) {
    //   return <RsvpForm {...this.props}
    //     openAsOverlay={true}
    //     sharedState={this.state.rsvpFormState}
    //     handleOpen={() => this.setState({ formIsOpen: true })}
    //     handleClose={() => this.setState({ formIsOpen: false })}
    //     handleUpdate={this.handleRsvpFormUpdate}
    //   />
    // }
  }

  public maybeRenderFormInLeftCol()
  {
    // if (this.state.screenIsNarrow) {
    //   return <RsvpForm {...this.props}
    //     openAsOverlay={false}
    //     sharedState={this.state.rsvpFormState}
    //     handleOpen={() => this.setState({ formIsOpen: true })}
    //     handleClose={() => this.setState({ formIsOpen: false })}
    //     handleUpdate={this.handleRsvpFormUpdate}
    //   />
    // }
  }

  public renderDayTwo()
  {
    return (
      <section className={this.state.screenIsNarrow ? 'right-in-left day-two' : 'day-two'}>
        <h1>Jaymahal Palace</h1>

        <p className='subtitle'><strong>3 November 2019</strong></p>

        <p>
          Ceremony: 9:30 am - 12:30 pm<br/>
          Muhurtam: 12:15 pm<br/>
          Lagnam: Abhijit
          <span className='small-br'></span>
          Golden Lawn, Jaymahal Palace<br/>
          1 Jaymahal Road, Bengaluru 560044<br/>
          <span className='small-br'></span>
          Lunch to follow
        </p>

        {this.maybeRenderFormInRightCol()}
      </section>
    )
  }

  public renderDayOne()
  {
    return (
      <section className={this.state.screenIsNarrow ? 'right-in-left day-one' : 'day-one'}>
        <h1>Century Club</h1>
        <p className='subtitle'><strong>2 November 2019</strong></p>
        <p>
          6:00 pm
          <span className='small-br'></span>
          Wadiyar Hall<br/>
          Century Club<br/>
          1, Seshadri Rd, Bengaluru 560001
          <span className='small-br'></span>
          Dinner to follow
          <span className='small-br'></span>
          Attire: colors and sequins
        </p>

        {this.maybeRenderFormInRightCol()}
      </section>
    )
  }

  public render()
  {
    return (
      <div className='bangalore-container'
        style={{
          width: '100%',
          overflowX: 'hidden',
        }}>
        <div className='bangalore-inner'>
          <div className='column-container left'>
            <div className='logo-container' style={{
              padding: '50px 50px 30px'
            }}>
              <img className='logo' src={logo} alt='Bangalore' style={{
                width: '100%',
                height: 'auto',
              }} />
            </div>
            <div className='column-inner left'
              style={{
                paddingBottom: this.state.formIsOpen && !this.state.screenIsNarrow
                  ? getRsvpFormHeight(200) : '200px'
              }}>
              {this.maybeRenderFormInLeftCol()}
              <section>
                <div className='subsection'>
                  <p>
                    Smt. Sulatha Shanbhogue
                    <br />
                    (w/o Late Sri K.V. Shanbhogue)
                    <br />
                    <small>and</small> Smt. Meera and <span className='break-mobile'></span>Sri Srinivasan Chellappa
                  </p>

                  <p><strong>request the honor of your presence at the wedding of their grand-daughter</strong></p>

                  <p className='display large'>Esha Neha Shanbhogue</p>

                  <p>daughter of Chitra and <span className='break-mobile'></span>Vasant Shanbhogue <small>with</small></p>

                  <p className='display large'>Daniel A. Mayer</p>

                  <p>son of Elishka Williams <span className='break-mobile'></span>and David Mayer</p>
                </div>
                <div className='subsection'>
                  <p><strong>With best compliments from</strong></p>
                  <p>
                    Dr. Jagdish and Asha Mallya (SG)<br />
                    Mr. Sridhar and Kiran Chellappa (US)<br />
                    Mr. Narendra and Anuradha Baliga (IN)<br />
                    <span className='small-br'></span>
                    Mrs. Barbara Mayer (US)<br />
                    Mrs. Elishka Podsklanova (CZ)<br />
                    Mr. David Jack and Dr. Amy Mayer (US)<br />
                    Mr. Jonathan Talbott and Nancy Mayer (NL)<br />
                    Mr. Robert Gordon (US)
                  </p>
                </div>
                <div className='subsection'>
                  <p><strong>No boxed gifts, please</strong></p>
                </div>
                <div className='subsection'>
                  {/*<p><strong>
                    Please send regrets by 1<small>st</small> August 2019 to
                    <br />
                    <a href='mailto:dannyandesha@gmail.com'>dannyandesha@gmail.com</a>
                  </strong></p>*/}
                  <p><strong>
                    Please reply to
                    <br />
                    <a href='mailto:dannyandesha@gmail.com'>dannyandesha@gmail.com</a>
                  </strong></p>
                </div>
              </section>
              {this.state.screenIsNarrow ? this.renderDayTwo() : ''}
              <hr />
              <section>
                <div className='subsection'>
                  <p>Ahana Shanbhogue <span className='break-mobile'></span>and Paul Mayer</p>
                  <p><strong>invite you to an evening of music and dance to celebrate their siblings</strong></p>

                  <p className='display large'>Esha and Danny</p>
                </div>
                <div className='subsection'>
                  <p><strong>Come prepared to participate in the pre-wedding festivities—all talents welcome!</strong></p>
                </div>
                <div className='subsection'>
                  {/*<p><strong>
                    Please send regrets by 1<small>st</small> August 2019 to
                    <br />
                    <a href='mailto:dannyandesha@gmail.com'>dannyandesha@gmail.com</a>
                  </strong></p>*/}
                  <p><strong>
                    Please reply to
                    <br />
                    <a href='mailto:dannyandesha@gmail.com'>dannyandesha@gmail.com</a>
                  </strong></p>
                </div>
              </section>
              {this.state.screenIsNarrow ? this.renderDayOne() : ''}
            </div>
          </div>

          {!this.state.screenIsNarrow ? this.renderRightCol() : ''}
        </div>
      </div>
    )
  }
}
