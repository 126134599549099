import { FormEvent } from 'react'
import { ORLANDO_IS_LOGGED_IN } from './Cookies'
import './LoginForm.scss'

function setSessionCookie(): void
{
  document.cookie = `${ORLANDO_IS_LOGGED_IN}=true`
}

function handleSubmit(event: FormEvent<HTMLFormElement>): void {
  const form = event.target as HTMLFormElement
  const passwordInput = form.elements[0] as HTMLInputElement
  event.preventDefault()
  if (passwordInput.value === 'sohcahtoa') {
    setSessionCookie()
  }
  window.location.reload()
}

export function LoginForm()
{
  return <form id='login-form'
    autoComplete='off'
    onSubmit={handleSubmit}>
    <div id='login-form--inner'>
      <input id='login-form--name'
        type='text'
        autoComplete='off'
        placeholder='Enter the password'
      />
    </div>
    <input type='submit' style={{display: 'none'}} />
  </form>
}
