import { OrlandoRsvp } from '../../rpc/RsvpModel'

export class OrlandoState
{
  public mastheadIsFocused = true
  public screenIsNarrow?: boolean
  public rsvps?: OrlandoRsvp[]
  public activeRsvp?: OrlandoRsvp
  public lastActiveRsvp?: OrlandoRsvp
  public scrollToAfterNavigation?: number
  public activeRsvpIsAnimating?: boolean
  public isLoggedIn?: boolean
}
