import { AppToast } from './AppModel'
import { OrlandoRsvp } from './rpc/RsvpModel'

export class StatelessAction<PayloadType = undefined> {
  constructor(public payload?: PayloadType) { }
}

export class StatefulAction<PayloadType = any> {
  constructor(public payload: PayloadType) { }
}

export type Action<PayloadType = any> = StatefulAction<PayloadType> | StatelessAction<PayloadType>

export class ToastEnqueue extends StatefulAction<AppToast> { }
export class OrlandoRsvpSelect extends StatefulAction<string> { }
export class OrlandoRsvpSelectSuccess extends StatefulAction<OrlandoRsvp> { }
export class ActiveOrlandoRsvpUpdate extends StatefulAction<Partial<OrlandoRsvp>> { }
export class OrlandoRsvpClose extends StatefulAction<OrlandoRsvp | undefined> { }
export class OrlandoRsvpForget extends StatelessAction { }
export class ScreenIsNarrowUpdate extends StatefulAction<boolean> { }
export class OrlandoRsvpOpenAnimationStart extends StatelessAction { }
export class OrlandoRsvpOpenAnimationEnd extends StatelessAction { }
export class OrlandoRsvpCloseAnimationKeyframe extends StatefulAction<number> { }
export class OrlandoRsvpSelectOnReturn extends StatefulAction<string> { }
export class OrlandoRsvpSelectOnReturnSuccess extends StatefulAction<OrlandoRsvp> { }

export type AppAction = ToastEnqueue |
  OrlandoRsvpSelect |
  OrlandoRsvpSelectSuccess |
  ActiveOrlandoRsvpUpdate |
  OrlandoRsvpClose |
  OrlandoRsvpForget |
  ScreenIsNarrowUpdate |
  OrlandoRsvpOpenAnimationStart |
  OrlandoRsvpOpenAnimationEnd |
  OrlandoRsvpCloseAnimationKeyframe |
  OrlandoRsvpSelectOnReturn |
  OrlandoRsvpSelectOnReturnSuccess
