import { Component } from 'react'
import { NavLink, RouteComponentProps } from 'react-router-dom'

export interface OrlandoNavigationState
{
  isNavHovered: boolean
}

export class OrlandoNavigation
  extends Component<RouteComponentProps & { isHidden: boolean }, OrlandoNavigationState>
{
  public state = {
    isNavHovered: false,
  }

  public scrollToRoute(): void
  {
    setTimeout(() =>
    {
      const routesElement = document.getElementById('orlando-h1-below') as HTMLElement
      const scrollPositionY = window.scrollY
      const h1BelowYPos = routesElement
        ? routesElement.getBoundingClientRect().top + scrollPositionY - 20
        : 0
      window.scrollTo({
        top: h1BelowYPos,
        behavior: 'smooth',
      })
    })
  }

  public render()
  {
    const { match } = this.props
    const { isNavHovered } = this.state

    return !this.props.isHidden && (
      <nav className='nav'
        id='orlando-navigation'
        onMouseEnter={() => this.setState({ isNavHovered: true })}
        onMouseLeave={() => this.setState({ isNavHovered: false })}>

        <NavLink className={_getNavItemClassName(isNavHovered)}
          activeClassName='active'
          to={`${match.path}/rsvp`}
          onClick={() => this.scrollToRoute()}>
          <span className='nav-item--text'>RSVP</span>
        </NavLink>

        <NavLink className={_getNavItemClassName(isNavHovered)}
          activeClassName='active'
          to={`${match.path}/about`}
          onClick={() => this.scrollToRoute()}>
          <span className='nav-item--text'>About</span>
        </NavLink>

        <NavLink className={_getNavItemClassName(isNavHovered)}
          activeClassName='active'
          to={`${match.path}/the-day-of`}
          onClick={() => this.scrollToRoute()}>
          <span className='nav-item--text'>The Day Of</span>
        </NavLink>

        <NavLink className={_getNavItemClassName(isNavHovered)}
          activeClassName='active'
          to={`${match.path}/book-exchange`}
          onClick={() => this.scrollToRoute()}>
          <span className='nav-item--text'>Book Exchange</span>
        </NavLink>

        <NavLink className={_getNavItemClassName(isNavHovered)}
          activeClassName='active'
          to={`${match.path}/hotels`}
          onClick={() => this.scrollToRoute()}>
          <span className='nav-item--text'>Hotels</span>
        </NavLink>

        <NavLink className={_getNavItemClassName(isNavHovered)}
          activeClassName='active'
          to={`${match.path}/things-to-do`}
          onClick={() => this.scrollToRoute()}>
          <span className='nav-item--text'>Things To Do</span>
        </NavLink>

        <NavLink className={_getNavItemClassName(isNavHovered)}
          activeClassName='active'
          to={`${match.path}/registry`}
          onClick={() => this.scrollToRoute()}>
          <span className='nav-item--text'>Registry</span>
        </NavLink>
      </nav>
    )
  }
}

const _navItemClassName = 'nav-item'
function _getNavItemClassName(isNavHovered: boolean): string
{
  let className = _navItemClassName
  if (isNavHovered) className += ' nav-is-hovered'
  return className
}
